import { Box, Flex, Grid,Text } from "@chakra-ui/react";
import { TextAreaFormControl, InputStepperFormControl } from "../../Component/formComponent";
import { localeStrings } from "../../utils/i18n";
import AccordionTitleDescription from './AccordionTitleDescription/AccordionTitleDescription.json';
import { AccordionComponent } from "./AccordionTitleDescription/Accordion";
import { EIGHT_8D_SECTION } from "../../utils/const";

function D7PreventiveAction({view, audit8DLogId}: {view : boolean; audit8DLogId?:number;}) {
  return (
    <>
       <Flex justifyContent={"space-between"}>
       <Text pb={2} fontWeight="bold" fontSize={'md'}>{EIGHT_8D_SECTION.D7_PREVENTION_ACTION}
       </Text>
       {audit8DLogId && <Text fontSize={'md'} fontWeight={"medium"}>{audit8DLogId}</Text>}
       </Flex>
    <AccordionComponent Title={AccordionTitleDescription.D7.Title} Description={AccordionTitleDescription.D7.Description} />
    <Box width={[ '100%','100%','60%','100%']}>
      <TextAreaFormControl
        required={true}
        data-testid="prevention_action"
        label={localeStrings("CREATE_8D_LOG.PREVENTION_ACTION.TITLE")}
        placeholder={localeStrings("PLACEHOLDERS.ENTER_PREVENTION_ACTION")}
        name={"preventionAction"}
        maxLength={1000}
        disabled={true}
      />
      <Grid my={6} columnGap={10} gridTemplateColumns={["1fr","1fr 1fr"]}>
        <InputStepperFormControl
          required={false}
          data-testid="cost_of_quality"
          label={localeStrings("CREATE_8D_LOG.PREVENTION_ACTION.COST_OF_QUALITY")}
          placeholder={localeStrings("PLACEHOLDERS.ENTER_COST_OF_QUALITY")}
          currencySymbol={"$"}
          min={0}
          precision={2}
          step={0.2}
          name={"costOfQuality"}
          disabled={view}
        />
      </Grid>
    </Box>
    </>
  );
}

export default D7PreventiveAction;
