import { optionType } from "./FormComponent";
import { FormControl, FormErrorMessage, FormLabel, Select } from "@chakra-ui/react";
import { useField } from "formik";
import Label from "./label";

export default function FormSelect({
  label,
  name,
  options,
  error,
  value,
  touched,
  placeholder,
  setvalue,
  required,
  labelStyle,
  fieldStyle,
  disabled,
  children,
  thumbnailImages,
  ...rest
}: optionType) {
  return (
    <FormControl isInvalid={error && touched ? true : false} isRequired={required}>
      {/* <FormLabel {...labelStyle} htmlFor={name}>{label}</FormLabel> */}
      {label && <Label name={name} labelStyle={labelStyle} label={label} thumbnailImages={thumbnailImages} />}
      <Select
        id={name}
        {...fieldStyle}
        {...rest}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        color={value ? "black" : "grey"}
      >
        {children ? children : options?.map((option, index) => (
          <option
            style={{ color: "black" }}
            key={`${index}_${option.value}`}
            data-testid={`${name}_${option.value}`}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage as="div">
        <>{error}</>
      </FormErrorMessage>
    </FormControl>
  );
}

export function SelectFormControl({options, name,handleSelect, ...rest }: optionType & { handleSelect?: (e:any) => void}) {
  const [field, { error, touched }, helper] = useField(name);
// eslint-disable-next-line
  return <FormSelect {...rest} {...field} options={options} error={error} touched={touched} value={typeof field.value === "object" ?  field?.value?.value : field?.value ?? 0} onChange={e =>{ helper.setValue(options.find(i => i.value == e.target.value)); if(handleSelect) handleSelect(options.find(i => i.value == e.target.value));}}/>;
}
