import { useMemo, createContext, useContext, useState, ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChakraThemeProvider from "../utils/theme";
import AlertContextProvider from "./AlertProvider";
import FullScreenLoaderContextProvider from "./FullScreenLoaderProvider";
import { FilterContextProvider } from "./FilterProvider";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry : false
    },
  },
});
export type appContextStoreType = {
  menu : {
    data : any;
    loading : boolean
  };
  setMenu : (a : any) => void;
  userEmail : string;
  userRoleId : number;
  userRoleName : string;
};
export const AppContext = createContext<any>({});
export const useAppContext = () => {
  const data: appContextStoreType = useContext(AppContext);
  return data;
};
export const AppProviders = ({ children }: { children: ReactNode }) => {
  const [menu, setMenu] = useState<any>({loading : true, data : {}});
  const value = useMemo(() => ({ menu, setMenu,
    userRoleId : menu.data?.application?.applicationRoles?.roleId,
    userRoleName  : menu.data?.application?.applicationRoles?.roleName,
    userEmail : menu.data?.userName
  }), [menu, setMenu]);
  return (
    <ChakraThemeProvider>
      <ToastContainer 
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light" />
      <FilterContextProvider>
      <AppContext.Provider value={value}>
        <QueryClientProvider client={queryClient}>
          <FullScreenLoaderContextProvider>
                <AlertContextProvider>{children}</AlertContextProvider>
          </FullScreenLoaderContextProvider>
        </QueryClientProvider>
      </AppContext.Provider>
      </FilterContextProvider>
    </ChakraThemeProvider>
  );
};
