import { optionType } from "./FormComponent";
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useField } from "formik";
import { Select } from "chakra-react-select";
import Label from "./label";

export default function FormMultiSelect({
  label,
  name,
  options = [],
  error,
  value,
  touched,
  placeholder,
  setvalue,
  required,
  labelStyle,
  fieldStyle,
  disabled,
  thumbnailImages,
  isMulti = true,
  onChange = () => {},
  ...rest
}: optionType) {
  
  return (
    <FormControl isInvalid={error && touched ? true : false} isRequired={required}>
      {/* <FormLabel {...labelStyle} htmlFor={name}>
        {label}
      </FormLabel> */}
      {label && <Label name={name} labelStyle={labelStyle} label={label} thumbnailImages={thumbnailImages} />}
      <Select
        id={name}
        isMulti={isMulti}
        closeMenuOnSelect={isMulti ? false : true}
        hideSelectedOptions={isMulti ? false : true}
        onChange={(selected) => {
          isMulti && selected.length && selected.find((option:any) => option.value === "all")
            ? onChange(options)
            : onChange(selected);
        }}
        placeholder={placeholder}
        isDisabled={disabled}
        chakraStyles={{
          dropdownIndicator: (provided) => ({
            ...provided,
            p: 1,
            bg: "transparent",
          }),
          control: (provided, state) => ({
            ...provided,
            width: rest.width ?? "100%",
            borderBottomLeftRadius: state.menuIsOpen ? 0 : "md",
            borderBottomRightRadius: state.menuIsOpen ? 0 : "md",
            transitionDuration: 0,
            textOverflow: "ellipsis",
            height: "10",
            overflow: "hidden",
            _placeholder: {
              fontWeight: "none",
            },
          }),
          placeholder: (provided) => ({
            ...provided,
            fontWeight: 400,
            fontSize: "sm",
            margin: 0.1,
          }),

          menu: (provided) => ({
            ...provided,
            width: rest.width ?? "100%",
            my: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            shadow: `0 0 0 1px 1px`,
            borderWidth: "1px",
            borderColor: "brand.grey",
            borderBottomRadius: "md",
          }),
          menuList: (provided) => ({
            ...provided,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderWidth: 0,
          }),
        }}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        value={value}
        components={
          isMulti
            ? {
                MultiValueContainer: MultiValueContainer,
              }
            : {
            }
        }
        options={
          options?.length
            ? isMulti
              ? [
                options.find((a:any) => a?.isDisabled) ? {label: "Select All", value: "all" , isDisabled : true} :  { label: "Select All", value: "all" },
                  ...options.map((i) => ({  ...i, label: i.label, value: i.value })),
                ]
              : options.map((i) => ({ label: i.label, value: i.value}))
            : []
        }
        selectedOptionStyle={isMulti ? "check" : "color"}
      />
      <FormErrorMessage as="div">
        <>{error}</>
      </FormErrorMessage>
    </FormControl>
  );
}

export function MultiSelectFormControl({ options, name, ...rest }: optionType) {
  const [field, { error, touched }, helper] = useField(name);
  // eslint-disable-next-line
  return (
    <FormMultiSelect
      {...rest}
      {...field}
      options={options}
      error={error}
      touched={touched}
      value={
        field?.value?.length
          ? field?.value?.map((item: any) =>
              typeof item === "string" ? { label: item, value: item } : item
            ) ?? []
          : field.value
      }
      onChange={(e) => helper.setValue(e)}
    />
  );
}

const MultiValueContainer: any = ({ selectProps, data }: any) => {
  const label = data.label;
  const allSelected = selectProps.value;
  const index = allSelected.findIndex((selected: any) => selected.label === label);
  const isLastSelected = index === allSelected.length - 1;

  return isLastSelected ? `Selected [${allSelected.length}]` : "";
};
