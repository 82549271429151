import { Box, Flex, Grid, Text } from "@chakra-ui/react";

import { TextAreaFormControl} from "../../Component/formComponent";
import { FieldArray, useField } from "formik";
import { localeStrings } from "../../utils/i18n";
import { Fragment } from "react";
import { useAlertContext } from "../../providers/AlertProvider";
import { PlusCircleFill, Trash3 } from "react-bootstrap-icons";
import { EIGHT_8D_SECTION } from "../../utils/const";

const spacing = "6";
export default function FiveWhy({view, audit8DLogId}: {view : boolean; audit8DLogId?:number;}) {
  const [,, helper] = useField("multiRecordUpdate");
  const { openAlert, setAlertProp, closeAlert } = useAlertContext();
  function deleteAlert(index:number, form : any){
    setAlertProp({
      type: "confirmation",
      alertMessage: localeStrings("ALERT.FIVE_WHY_DELETE_CONFIRMATION"),
      alertLabel: localeStrings("ALERT.DELETE_CONFIRMATION"),
      secondaryButtonLabel: localeStrings("COMMON.YES_DELETE"),
      primaryButtonLabel: localeStrings("COMMON.NO_DONT"),
      onClickPrimary: () => {
        closeAlert();
      },
      onClickSecondary: () => {  
          helper.setValue(true);
          let fiveWhys = form.values.fiveWhys.filter((i:any, ind:number) => index !== ind);
          form.setFieldValue("fiveWhys", fiveWhys);
        closeAlert();
      },
    });
    openAlert();
    return;
  }
  function numericLabel(i: number) {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
  }
  return (
    <>
      <Box mb={5}>
      <Flex justifyContent={"space-between"}>
       <Text pb={2} fontWeight="bold" fontSize={'md'}>{EIGHT_8D_SECTION.FIVE_WHY}
       </Text>
       {audit8DLogId && <Text fontSize={'md'} fontWeight={"medium"}>{audit8DLogId}</Text>}
       </Flex>
      <Box>
          <Grid templateColumns={{sm : "repeat(1, 1fr)", md :"repeat(2, 1fr)"}} gap={6}>
          <TextAreaFormControl
            required={true}
            placeholder={localeStrings("PLACEHOLDERS.ENTER_CELL_OR_EQUIPEMNET")}
            data-testid="write_cell_or_equipment"
            label={localeStrings("CREATE_8D_LOG.WRITE_CELL_OR_EQUIPMENT")}
            name="writeCellOrEquipment"
            disabled={view}
            rows={1}
            maxLength={100}
          />
            <TextAreaFormControl
            required={true}
            data-testid="understanding_point"
            label = {localeStrings("CREATE_8D_LOG.PROBLEM_DESCRIPTION")}
            placeholder = {localeStrings("PLACEHOLDERS.PROBLEM_DESCRIPTION")}
            name="underStandingPoint"
            disabled={true}
            rows={1}
            maxLength={1000}
          />
           
          </Grid>
        </Box>
        
        
        <Box mt={spacing}>
          <FieldArray name="fiveWhys">
          {({ push, form }) => {
            const { values } = form;
            return (
              <>
               <fieldset style={{border:'1.5px solid #D9DEE8',borderRadius:'10px'}}>
                 <legend style={{marginLeft:"20px",color:"#1C57A7",fontSize:"14px",fontWeight:"bold",padding:'5px'}}>{localeStrings("CREATE_8D_LOG.5_WHY_ROOT_CAUSE_ANALYSIS")}</legend>
              <Grid 
                templateColumns={"repeat(1, 1fr)"}
                gap={3}
                borderRadius={'5px'}
                p={3}
                mt={0}
                >
                {values.fiveWhys?.map((member: any, index: number) => {
                  if(member?.isDeleted) return null;                        
                  return (
                    <Fragment  key={index}>
                      <Flex position={'relative'}>
                        <TextAreaFormControl
                          required={true}
                          data-testid={`why${index+1}`}
                          bg={'#F5F7FA'}
                          labelStyle={{color:'brand.primaryBrand'}}
                          label={`${numericLabel(index+1)} Why?`}
                          placeholder={localeStrings('PLACEHOLDERS.ENTER_HERE')}
                          name={`fiveWhys[${index}].whatCause`}
                          disabled={view}
                          rows={1}
                          onChange={() => helper.setValue(true)}
                          maxLength={500}
                        />
                        <Flex alignItems="center" zIndex={1}>
                          <Box 
                            position={'absolute'} 
                            right={'50px'} 
                            cursor={'pointer'}
                            bottom={'-12px'}>
                            {index >= 3 && (
                              <>
                              {!view && index !== 0 &&
                                <Box
                                  color="#ff4e4e"
                                  borderRadius={'50%'}
                                  bg={'brand.white'}
                                  border={'1px solid #ff4e4e'}
                                  p={1}
                                  data-testid="5_why_delete_button" 
                                  onClick={() => {deleteAlert(index, form)}}
                                >
                                <Trash3 />
                              </Box>}
                              </>
                            )}
                            
                          </Box>
                          
                        </Flex>
                        
                      </Flex>
                      
                    </Fragment>
                  );
                })}
                <Flex cursor={'pointer'} marginLeft={'auto'} alignItems={'center'} onClick={() =>{ push({ serialNo:values?.fiveWhys?.reduce((pre:any, curr:any) => pre < curr.serialNo ? curr.serialNo + 1 : pre + 1, 1), whatCause: "", whatFailed:"", whyProblem:""});     helper.setValue(true);}}>
                    
                    {!view && <><PlusCircleFill data-testid='five_why_add_button' style={{marginLeft:10}} color="#2196F3" fontSize={20}/>
                    <Text ml={2} color={'#2196F3'}>{localeStrings("COMMON.ADD_WHY")}</Text></>}
                  </Flex>
              </Grid>
              </fieldset>
              </>
            );
          }}
        </FieldArray>
        </Box>

        <Box pt={spacing}>
          <Grid templateColumns={{sm : "repeat(1, 1fr)", md :"repeat(2, 1fr)"}} gap={6}>
            <TextAreaFormControl
              required={true}
              data-testid="root_causes"
              label={localeStrings("CREATE_8D_LOG.ROOT_CAUSES")}
              placeholder={localeStrings("PLACEHOLDERS.ENTER_ROOT_CAUSES")}
              name="rootCause"
              disabled={view}
              rows={1}
              maxLength={1000}
            />
            <TextAreaFormControl
              required={true}
              data-testid="corrective_action"
              label={localeStrings("CREATE_8D_LOG.CORRECTIVE_ACTION")}
              placeholder={localeStrings("PLACEHOLDERS.ENTER_CORRECTIVE_ACTION")}
              name="correctiveAction"
              disabled={view}
              rows={1}
              maxLength={1000}
            />
            <TextAreaFormControl
              required={true}
              data-testid="prevention_action"
              label={localeStrings("CREATE_8D_LOG.PREVENTION_ACTION.TITLE")}
              placeholder={localeStrings("PLACEHOLDERS.ENTER_PREVENTION_ACTION")}
              name={"preventionAction"}
              maxLength={1000}
              rows={1}
              disabled={view}
            />
          </Grid>
        </Box>
      </Box>
    </>
  );
}