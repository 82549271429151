import { Box,Text } from "@chakra-ui/react";
import styles from "./SwitchTab.module.css";
import { CheckLg } from "react-bootstrap-icons";
import { Fragment } from 'react';
import { MODULE_POSITION } from "../../Pages/Create8D";

type SwitchTabProp = {
  style?: Object;
  tabs: string[];
  currentPosition: number;
  steps?: boolean,
  statusId? : number
};

export function SwitchTab({ style, tabs, currentPosition,steps=false,statusId }: SwitchTabProp) {
  
  let stepsArr = [['0','1','2','3'],['4'],['5','6', '7'],['8']];
  if(steps){
    return (
      <Box {...style}>
        {stepsArr.map((val,ind) => {
          return (
            <Fragment key={ind + 1}>
            <Text opacity={0.5} pb={2}>STEP {ind + 1}</Text>
            <div className={styles["timeline-container"]}>
            {val.map((tab, index) => (
              <TimelineItems data={tabs[parseInt(tab)]} key={tab} index={parseInt(tab)} currentPosition={currentPosition} steps={steps}  statusId={statusId} />
            ))}
          </div>
          
          </Fragment>
          )
        })}
     
      </Box>
    );
  }


  return (
    <Box {...style}>
      {tabs.length > 0 && (
        <div className={styles["timeline-container"]}>
          {tabs.map((tab, index) => (
            <TimelineItem data={tab} key={index} index={index} currentPosition={currentPosition} />
          ))}
          
        </div>
      )}
    </Box>
  );
}

const TimelineItem = ({
  data,
  index,
  currentPosition,
  steps=false,
}: {
  data: string;
  index: number;
  currentPosition: number;
  steps?: boolean;
}) => {
  let isActive = currentPosition === index;
  let isCompleted = currentPosition > index;

  return (
    <div className={(steps  && index === MODULE_POSITION.D7) ? '' : styles["timeline-item"]} data-testid="no-of-tabs">
      <div className={styles["timeline-item-content"]}>
        {isCompleted ? (
          <>
            <p style={isCompleted ? { fontWeight: "bold", color: "#0F968C" } : {}}>{data}</p>
            <span
              className={styles["circle"]}
              data-testid="Checked-tabs"
              style={isCompleted ? { backgroundColor: "#0F968C", color: "white" } : {}}
            >
              <CheckLg />
            </span>{" "}
          </>
        ) : (
          <>
            <p style={isActive ? { fontWeight: "bold" } : { color:"#4E5A6A"}}>{data}</p>
            <span
              className={styles["circle"]}
              style={isActive ? { backgroundColor: "#0068B3", color: "white" } : {color:"#0068B3"}}
            >
              {index + 1}
            </span>
          </>
        )}
      </div>
    </div>
  );
};



const TimelineItems = ({
  data,
  index,
  currentPosition,
  steps=false,
  statusId
}: {
  data: string;
  index: number;
  currentPosition: number;
  steps?: boolean;
  statusId?: number;
}) => {
  let isActive = currentPosition === index;
  let isCompleted = currentPosition > index;
  return (
    <div className={(steps  && index === MODULE_POSITION.D7 || index === MODULE_POSITION.D3 ) ? '' : styles["timeline-item"]} data-testid="no-of-tabs">
      <div className={styles["timeline-item-content"]}>
        {isCompleted ? (
          <>
            <p style={isCompleted ? { fontWeight: "bold", color: "#0F968C" } : {}}>{data}</p>
            {steps && (index === MODULE_POSITION.D3 || index === MODULE_POSITION.D7) ? <p style={{fontSize:'12px',color:"#0056BE"}}>{subTitleName(statusId ?? 1)[index === MODULE_POSITION.D3 ? 0 : 1]}</p> : <></> }
            <span
              className={styles["circle"]}
              data-testid="Checked-tabs"
              style={isCompleted ? { backgroundColor: "#0F968C", color: "white" } : {}}
            >
              <CheckLg />
            </span>{" "}
          </>
        ) : (
          <>
            <p style={isActive ? { fontWeight: "bold" } : { color:"#4E5A6A"}}>{data}</p>
            {steps && (index === MODULE_POSITION.D3 || index === MODULE_POSITION.D7) ? <p style={{fontSize:'12px',color:"#0056BE"}}>{subTitleName(statusId ?? 1)[index === MODULE_POSITION.D3 ? 0 : 1]}</p>  : <></> }

            <span
              className={styles["circle"]}
              style={isActive ? { backgroundColor: "#0068B3", color: "white" } : {color:"#0068B3"}}
            >
              {index + 1}
            </span>
          </>
        )}
      </div>
    </div>
  );
};


const subTitleName = (i: number) => {
  if(i === 3){
    return ['Review Completed','Supervisor Approval'];
  }else if(i === 6){
    return ['Review Completed','Review Completed'];

  }else{
    return ['Supervisor Approval(optional)','Supervisor Approval'];
  }
}
