import { Box, Flex, Text } from "@chakra-ui/react";

export default function MobilePageCountHeader({
  currentLabel,
  currentPosition,
  total,
}: {
  currentLabel: string;
  currentPosition: number;
  total: number;
}) {
  return (
    <Flex
      p={"3"}
      display={["flex","flex","none","none"]} 
      backgroundColor={"var(--tk-colors-blackAlpha-50)"}
      color={"#0068B3"}
      fontWeight={"var(--tk-fontWeights-semibold)"}
      justifyContent={"space-between"}
    >
      <Text
        color={"#1C57A7"}
        display={"flex"}
        alignItems={"center"}
        fontSize={"var(--tk-fontSizes-lg)"}
        fontWeight={"var(--tk-fontWeights-semibold)"}
      >
        {currentLabel}
      </Text>
      <Box
        borderRadius={"50%"}
        border={"1px solid var(--tk-colors-black)"}
        display={"flex"}
        alignItems={"end"}
        padding={1}
      >
        <Text fontSize={"var(--tk-fontSizes-xl)"}>{currentPosition}/</Text>
        <Text mb={0.5}>{total}</Text>
      </Box>
    </Flex>
  );
}
