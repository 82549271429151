import { Grid, Text, Flex } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import TitleWithLine from "../../Component/ui/TitleWithLine";
import {
  fetchD1FormData,
  fetchDefectCode,
  fetchDLocationDataByBusinessUnit,
} from "../../api/create8D";
import { localeStrings } from "../../utils/i18n";
import { useFullScreenLoader } from "../../providers/FullScreenLoaderProvider";
import { InputFormControl, MultiSelectFormControl, SelectFormControl } from "../../Component/formComponent";
import AccordionTitleDescription from "./AccordionTitleDescription/AccordionTitleDescription.json";
import { AccordionComponent } from "./AccordionTitleDescription/Accordion";
import { EIGHT_8D_SECTION } from "../../utils/const";
import { MODULE_POSITION } from ".";

function D0Form({ view, setEightDFormValue, audit8DLogId }: { view: boolean; setEightDFormValue : (e : any) => void; audit8DLogId?: number }) {
  const { values, setFieldValue }: any = useFormikContext();
  const { onOpenFullScreenLoader, onCloseFullScreenLoader } = useFullScreenLoader();
  const { data: defectCode } = useQuery(["DefectCode"], fetchDefectCode, {
    initialData: [],
  });

  const { data: dropDown } = useQuery(["businessUnitPurpose"], () => fetchD1FormData(), {
    initialData: {
      businessUnit: [],
      Purpose: [],
    },
  });
  const { data: locations, isRefetching } = useQuery(
    ["locations", values?.businessUnit],
    () => fetchDLocationDataByBusinessUnit(values?.businessUnit?.value),
    {
      enabled: values?.businessUnit ? true : false,
      initialData: [],
    }
  );
  useEffect(() => {
    if (isRefetching) onOpenFullScreenLoader();
    if (!isRefetching) onCloseFullScreenLoader();
    // eslint-disable-next-line
  }, [isRefetching]);
  return (
    <>
      <Flex justifyContent={"space-between"}>
        <Text pb={2} fontWeight="bold" fontSize={"md"}>
          {EIGHT_8D_SECTION.D0_PREPARE_8D}
        </Text>
        {audit8DLogId && <Text fontSize={'md'} fontWeight={"medium"}>{audit8DLogId}</Text>}
      </Flex>
      <AccordionComponent
        Title={AccordionTitleDescription.D0.Title}
        Description={AccordionTitleDescription.D0.Description}
      />
      <Grid
        templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)"]}
        gap={6}
      >
        <SelectFormControl
          required={true}
          placeholder={localeStrings("PLACEHOLDERS.SELECT_BUSINESS_UNIT")}
          label={localeStrings("CREATE_8D_LOG.BUSINESS_UNIT")}
          data-testid="business_select"
          disabled={view}
          name="businessUnit"
          options={dropDown?.businessUnit?.map((item: any) => ({
            label: item.businessUnitName,
            value: item.id,
          }))}
          handleSelect={() =>{ setFieldValue("location", "");
            setEightDFormValue((pre: any) => {
              let obj  = ({
                ...pre,
                [MODULE_POSITION.D5_6] : {
                  ...pre?.[MODULE_POSITION.D5_6],
                  responsiblePerson : []
                }
              })
              
              return obj
            });
          }}
        />
        <SelectFormControl
          required={true}
          placeholder={localeStrings("PLACEHOLDERS.SELECT_LOCATION")}
          label={localeStrings("CREATE_8D_LOG.LOCATION")}
          disabled={view}
          data-testid="location_select"
          name="location"
          options={locations?.map((item: any) => ({ label: item.locationName, value: item.id }))}
        />
        
        <InputFormControl
          required={true}
          placeholder={localeStrings("PLACEHOLDERS.ENTER_CUSTOMER")}
          label={localeStrings("CREATE_8D_LOG.CUSTOMER")}
          data-testid="customer_name"
          name="customer"
          disabled={view}
          showLength={false}
          maxLength={100}
        />
        <InputFormControl
          required={true}
          placeholder={localeStrings("PLACEHOLDERS.ENTER_CUSTOMER_NAME")}
          label={localeStrings("CREATE_8D_LOG.CUSTOMER_NAME")}
          data-testid="customer_name"
          name="customerName"
          disabled={view}
          showLength={false}
          maxLength={100}
        />
        <InputFormControl
          required={true}
          placeholder={localeStrings("PLACEHOLDERS.EMAIL_ID")}
          label={localeStrings("CREATE_8D_LOG.CUSTOMER_EMAIL_ID")}
          data-testid="customer_email"
          name="customerEmail"
          disabled={view}
          showLength={false}
          maxLength={200}
        />
        <SelectFormControl
          required={true}
          placeholder={localeStrings("PLACEHOLDERS.SELECT_PURPOSE")}
          label={localeStrings("CREATE_8D_LOG.PURPOSE")}
          data-testid="purpose_select"
          name="purpose"
          disabled={view}
          options={dropDown?.Purpose?.map((item: any) => ({
            label: item.purposeName,
            value: item.id,
          }))}
        />

        <MultiSelectFormControl
          required={true}
          placeholder={localeStrings("PLACEHOLDERS.SELECT_DEFECT_CODE")}
          label={localeStrings("CREATE_8D_LOG.DEFECT_CODE")}
          data-testid="defect_code"
          name="defectCode"
          isMulti={false}
          disabled={view}
          options={defectCode?.length  ? [...defectCode.map((item: any) => ({
            label: item.defectCodeName,
            value: item.id,
          })), { label : "Other", value : 0}] : []}
        />
        {values?.defectCode?.value === 0 && <InputFormControl
          required={true}
          placeholder={localeStrings("PLACEHOLDERS.ENTER_NEW_DEFECT_CODE")}
          label={localeStrings("CREATE_8D_LOG.NEW_DEFECT_CODE")}
          data-testid="new-defect-code-test"
          name="newDefectCode"
          showLength={false}
          maxLength={100}
          disabled={view}
        />}
        <InputFormControl
          required={false}
          placeholder={localeStrings("PLACEHOLDERS.ENTER_JOB_NUMBER")}
          label={localeStrings("CREATE_8D_LOG.JOB_NUMBER")}
          data-testid="jobNumber-test"
          name="jobNumber"
          infoIcon={true}
          showLength={false}
          maxLength={100}
          disabled={view}
        />
      </Grid>
    </>
  );
}

export default D0Form;
