import { azureFetcher } from "./fetcher";
import API_ENDPOINT from "./endpoints";

export async function getActionTrackerData({ PageNumber, RecordsPerPage, StatusID, businessUnit, location, searchString, assigneeMail, startDate, endDate }: any) {
    let statusFilter = StatusID !== 0 ? `&StatusID=${StatusID}` : "";
    let businessUnitFilter = businessUnit !== 0 ? `&BusinessUnitId=${businessUnit}` : "";
    let locationFilter = location !== 0 ? `&LocationId=${location}` : "";
    let searchStringFilter = searchString ? `&auditTemplateId=${searchString}` : "";
    let assigneeFilter = assigneeMail ? `&assigneeEmailId=${assigneeMail}` : "";
    let dateFilter = (startDate ? `&StartDate=${startDate}` : "") + (endDate ? `&EndDate=${endDate}` : "");

    try {
      const url = `?PageNumber=${PageNumber}&pageSize=${RecordsPerPage}${statusFilter}${businessUnitFilter}${locationFilter}${searchStringFilter}${assigneeFilter}${dateFilter}`;
      const data = await azureFetcher.get(API_ENDPOINT.GET_ACTION_TRACKER(url));
      if (data.status === 200) return data.data;
      return [];
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  export async function getActionTrackerDetailData({ auditActionTrackerId }: any) {
    try {
      const data = await azureFetcher.get(API_ENDPOINT.GET_ACTION_TRACKER_DETAILS(auditActionTrackerId));
      if (data.status === 200) return data.data;
      return [];
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  export async function updateActionTrackerDetails({ body }: any) {
    try {
      const data = await azureFetcher.post(API_ENDPOINT.UPDATE_ACTION_TRACKER_DETAILS, JSON.stringify(body));
      if (data.status === 200) return data.data;
      return [];
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  export async function exportExcelActionTracker({ PageNumber, RecordsPerPage, StatusID, businessUnit, location, searchString, assigneeMail, startDate, endDate, fileName }: any) {
    let statusFilter = StatusID !== 0 ? `&StatusID=${StatusID}` : "";
    let businessUnitFilter = businessUnit !== 0 ? `&BusinessUnitId=${businessUnit}` : "";
    let locationFilter = location !== 0 ? `&LocationId=${location}` : "";
    let searchStringFilter = searchString ? `&auditTemplateId=${searchString}` : "";
    let assigneeFilter = assigneeMail ? `&assigneeEmailId=${assigneeMail}` : "";
    const dateFilterQuery = (startDate ? `&startDate=${startDate}` : "") + (endDate ? `&endDate=${endDate}` : "");
    try {
      const url = `?PageNumber=${PageNumber}&pageSize=${RecordsPerPage}${statusFilter}${businessUnitFilter}${locationFilter}${searchStringFilter}${assigneeFilter}${dateFilterQuery}`;
      const data = await azureFetcher.get(API_ENDPOINT.EXPORT_EXCEL_ACTION_TRACKER(url),{
        responseType: "blob",
      });
      
      if (data.status === 200) {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(data.data);
        link.download = fileName;
        link.click();
        return data.data; 
      }
      return [];
    } catch (err) {
      console.log(err);
      return err;
    }
  }
  