import loginLogo from "./images/loginLogo.svg";
import brandLogo from './images/logo.png';
import loginBG from './images/loginScreen.png';
import  uploadImagePlaceHolder from './images/dragAndDrop.svg';
import logoOnly from './images/logoOnly.svg';
import headerLogo from './images/headerLogo.png';

import success from './images/alert/success.png';
import confirmation from './images/alert/confirmation.svg';
import confirmationError from './images/alert/confirmationError.svg';
import file from './images/file.png';
import file2x from './images/file2x.png';
import sort from './images/sort.svg';
import pdf from './images/create8D/pdf.png';
import doc from './images/create8D/doc.png';
import excel from './images/create8D/excel.png'
import heic from './images/create8D/heic.png';

import like from './images/create8D/like.png';
import dislike from './images/create8D/dislike.png';
import header from './images/create8D/header.png';
import headerInt from './images/create8D/headerInt.png';
import exportPdf from './images/create8D/export.png';
import like_inactive from './images/create8D/like_inactive.png';
import dislike_inactive from './images/create8D/dislike_inactive.png';
import more from './images/more.png';
import menu from "./images/menu.svg";

import Audit_view from './images/ChooseModule/Audit_view.svg';
import D_view from './images/ChooseModule/8D_view.svg';
import D_selected from './images/ChooseModule/8D_selected.svg';
import Audit_selected from './images/ChooseModule/Audit_selected.svg';
import success_tick from './images/success_tick.svg';
import history from "./images/history.png";
export const images = {
    login: {
        loginLogo ,
        brandLogo,
        loginBG,
        logo : brandLogo,
        logoOnly
    },

    create8D : {
        uploadImagePlaceHolder,
        file,
        file2x,
        like,
        dislike,
        like_inactive,
        dislike_inactive,
        exportPdf,
        header,
        headerLogo,
        headerInt,
        more,
        pdf,
        doc,
        heic,
        excel
    },
    alert : {
        success,
        confirmation,
        confirmationError
    },
    tabel : {
        sort
    },
    ChooseModule : {
        Audit_view,
        D_view,
        D_selected,
        Audit_selected
    },
    menu : {
        menu
    },
    ManageAudit : {
        success_tick
    },
    icons : {
        history
    }
};
