import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { useField } from "formik";
import { InputStepperPropType } from "./FormComponent";
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';

export default function InputStepper({
  currencySymbol,
  value,
  onChange = (val:string) => {},
  placeholder,
  name,
  label,
  error,
  touched,
  required,
  disabled,
  ...rest
}: InputStepperPropType) {
  return (
    <FormControl isInvalid={error && touched ? true : false} isRequired={required}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <NumberInput
        id={name}
        {...rest}
        value={touched || value ? currencySymbol + value : value}
        onChange={(val) => onChange(val.replace(/^\$/, ''))}
        allowMouseWheel
      >
        <NumberInputField disabled={disabled} _disabled={{color :"#acacac", cursor: "not-allowed",}}   placeholder={placeholder} />
        {!disabled && <NumberInputStepper>
          <NumberIncrementStepper  border={"0px"} children={<ChevronUp />} />
          <NumberDecrementStepper border={"0px"} children={<ChevronDown />} />
        </NumberInputStepper>}
      </NumberInput>
      <FormErrorMessage as="div">{error}</FormErrorMessage>
    </FormControl>
  );
}

export function InputStepperFormControl({ name, ...rest }: InputStepperPropType) {
  const [field, { error, touched } , helper] = useField(name);
  const handleChange = (value: string) => {
    helper.setValue(value);
  };
  return <InputStepper {...rest} {...field} value={field.value} error={error} touched={touched} onChange={handleChange} />;
}
