import { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./web.config";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";

import { adInstance } from "./Auth/authConfigAAD";
import { UseRoute } from "./utils/Route";
import { BrandSpinner } from './Component/ui/BrandSpinner';

import "./index.css";
import { AppProviders } from "./providers/AppProviders";
import { Flex } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./utils/ErrorBoundary";

const Login = lazy(() => import("./Pages/Login/Login"));

function Index() {
  
  return (
    <ErrorBoundary>
    <AppProviders>
      <MsalProvider instance={adInstance}>
        <Suspense fallback={<Flex height={"100vh"} justifyContent="center" alignItems={"center"}><BrandSpinner /></Flex>}>
            <UnauthenticatedTemplate>
              <BrowserRouter> 
                <Login />
              </BrowserRouter> 
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <UseRoute />
            </AuthenticatedTemplate>
        </Suspense>
      </MsalProvider>
    </AppProviders>
    </ErrorBoundary>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<Index />);
