import { Box, Flex, Text } from "@chakra-ui/react";
import { useState, useMemo, useCallback, useEffect, useRef, FC } from "react";
import { ArrowLeft, ArrowRight, ChevronLeft } from "react-bootstrap-icons";
import { SwitchTab } from "../../Component/ui/SwitchTab";
import Button from "../../Component/ui/Button";
import { BoxShadowBorderRadius } from "../../utils/theme";
import D1Form from "./D1Form";
import D2Concern from "./D2Concern";
import D3Containment from "./D3Containment";
import D4RootCause from "./D4RootCause";
import D5CorrectiveAction from "./D5CorrectiveAction";
import { Form, Formik } from "formik";
import {
  D1InitialValue,
  D2InitialValue,
  D3InitialValue,
  D4InitialValue,
  FiveWhyInitialValue,
  D5InitialValue,
  D7InitialValue,
  D8FeedBackInitialValue,
  D0_PREPARE_8D_INITIAL_VALUE,
} from "./initialValue";
import {
  D2ValidationSchema,
  D3ValidationSchema,
  fiveWhyValidationSchema,
  purposeValidationSchema,
  D4ValidationSchema,
  D5ValidationSchema,
  preventionActionValidationSchema,
  cusFeedbackValidationScheme,
  D0_PREPARE_8D_VALIDATION,
  validateDraft,
  validateDraftDetails,
} from "./validationSchema";

import ScrollBar from "../../Component/ui/ScrollBar";
import {
  create8D5Whys,
  create8DConcern,
  create8DContainment,
  create8DProblemSolving,
  create8DPurpose,
  create8DRootCause,
  create8D_D5_D6,
  create8D_D7,
  create8D_D8,
  fetch8DData,
} from "../../api/create8D";
import { AlertProps, useAlertContext } from "../../providers/AlertProvider";
import FiveWhy from "./FiveWhy";
import { localeStrings } from "../../utils/i18n";
import D7PreventiveAction from "./D7PreventiveAction";
import { useNavigate, useParams } from "react-router-dom";
import { EIGHT_8D_SECTION, LOG_STATUS } from "./../../utils/const";
import { checkAllowSaveDraft, generateID } from "./../../utils/utils";
import D8FeedBack from "./D8FeedBack";
import MobilePageCountHeader from "../../Component/ui/MobilePageCountHeader";
import { BrandSpinner } from "../../Component/ui/BrandSpinner";
import { useFullScreenLoader } from "../../providers/FullScreenLoaderProvider";
import moment from "moment";
import D0Form from "./D0Prepare8D";
import { useMsal } from "@azure/msal-react";

export enum MODULE_POSITION  {
  D0 = 0,
  D1 = 1,
  D2 = 2,
  D3 = 3,
  FIVE_WHY = 4,
  D4 = 5,
  D5_6 = 6,
  D7 = 7,
  D8 = 8
}
export enum MODULE_POSITION_API  {
  D0 = 1,
  D1 = 2,
  D2 = 3,
  D3 = 4,
  FIVE_WHY = 5,
  D4 = 6,
  D5_6 = 7,
  D7 = 8,
  D8 = 9
}

let containerHeight = "calc(100vh - 140px)";
let MobileContainerHeight = "calc(100vh - 170px)";
let eightDForms: any = {
  [EIGHT_8D_SECTION.D0_PREPARE_8D]: {
    Component: D0Form,
    initialValue: () => D0_PREPARE_8D_INITIAL_VALUE,
    validationSchema: D0_PREPARE_8D_VALIDATION,
  },
  [EIGHT_8D_SECTION.D1_PURPOSE]: {
    Component: D1Form,
    initialValue: () => D1InitialValue,
    validationSchema: purposeValidationSchema,
  },
  [EIGHT_8D_SECTION.D2_CONCERN]: {
    Component: D2Concern,
    initialValue: () => D2InitialValue,
    validationSchema: D2ValidationSchema,
  },
  [EIGHT_8D_SECTION.D3_CONTAINMENT]: {
    Component: D3Containment,
    initialValue: () => D3InitialValue,
    validationSchema: D3ValidationSchema,
  },
  [EIGHT_8D_SECTION.FIVE_WHY]: {
    Component: FiveWhy,
    initialValue: () => FiveWhyInitialValue,
    validationSchema: fiveWhyValidationSchema,
  },
  [EIGHT_8D_SECTION.D4_ROOT_CAUSE]: {
    Component: D4RootCause,
    initialValue:  () => D4InitialValue,
    validationSchema: D4ValidationSchema,
  },
  [EIGHT_8D_SECTION.D5_6_CORRECTIVE_ACTION]: {
    Component: D5CorrectiveAction,
    initialValue:  () => D5InitialValue,
    validationSchema: D5ValidationSchema,
  },
  [EIGHT_8D_SECTION.D7_PREVENTION_ACTION]: {
    Component: D7PreventiveAction,
    initialValue: () => D7InitialValue,
    validationSchema: preventionActionValidationSchema,
  },
  [EIGHT_8D_SECTION.D8_CUST_FEEDBACK] : {
    Component: D8FeedBack,
    initialValue: () => D8FeedBackInitialValue,
    validationSchema: cusFeedbackValidationScheme,
  },
};



export function Create8D({currentposition = 0, state = {}, view = false, currentWorkFlowStepId = 0} : any) {
  const [skipApprove, setSkipApprove] = useState(false);
  const { accounts } = useMsal();
  const [currentPosition, setCurrentPosition] = useState<number>(currentposition);
  const [eightDFormValue, setEightDFormValue] = useState<any>({...state});
  const { openAlert, setAlertProp, closeAlert } = useAlertContext();
  const  { onOpenFullScreenLoader, onCloseFullScreenLoader} = useFullScreenLoader();
  const navigate = useNavigate();
  const contentRef = useRef<HTMLInputElement>(null);
  const progressLabels: any = Object.keys(eightDForms);
  let audit8DLogId = eightDFormValue.audit8DLogId ?? (eightDFormValue.id ? generateID({id : eightDFormValue.id, prefix : "8D"}) : undefined);
  let isSelectedInternalPurpose = (currentWorkFlowStepId ?? 0) <= 3 && (state?.isCurrentUser ?? eightDFormValue?.createdByUser === accounts[0]?.username) && eightDFormValue?.[MODULE_POSITION.D0]?.purpose?.value === 1 && currentPosition === MODULE_POSITION.D2;
  let value: {
    Component: FC;
    initialValue: any;
    validationSchema: any;
  } = useMemo(
    () => eightDForms[progressLabels[currentPosition]],
    [currentPosition, progressLabels]
  );
  let preLabel = useMemo(
    () => progressLabels[currentPosition - 1],
    [currentPosition, progressLabels]
  );
  let nextLabel = useMemo(
    () => progressLabels[currentPosition + 1],
    [currentPosition, progressLabels]
  );
  let goToNext = (form: any, id?: number, callback?: any) => {
    let idObj = id ? { id ,  createdByUser : callback?.updatedByUser ?? callback?.createdByUser } : {};
    let eightDFormValues = eightDFormValue;
    let updatedValue = form.values;
    if(currentPosition === MODULE_POSITION.D0){
      let oldTeamMembersValue = eightDFormValues[MODULE_POSITION.D1]?.teamMembers ?? undefined;
      updatedValue = {...updatedValue,id:callback?.id, multiRecordUpdate : false, defectCode : form.values.newDefectCode ? {value : `${callback.purpose8d[0].defectCodeId}`, label :   form.values.newDefectCode} : form.values.defectCode };
      eightDFormValues[MODULE_POSITION.D2] = ({ ...eightDFormValues[MODULE_POSITION.D2] ?? D2InitialValue, jobNumber : form.values.jobNumber});
      eightDFormValues[MODULE_POSITION.D5_6] = ({ ...eightDFormValues?.[MODULE_POSITION.D5_6] ?? D5InitialValue, 
        businessUnit: form.values.businessUnit.value,
        location: form.values.location.value,
      });
      let isSelectedInternal = callback.purpose8d[0].purposeId === 1 && (state.isCurrentUser ?? callback?.createdByUser === accounts[0]?.username) && !eightDFormValues?.[MODULE_POSITION.D1]?.teamMembers?.[0]?.teamMemberTitle;
      let isSelectedNonInternalOne = callback.purpose8d[0].purposeId !== 1;
      if(isSelectedNonInternalOne){
        eightDFormValues[MODULE_POSITION.D1] = ({ ...eightDFormValues[MODULE_POSITION.D1] ?? D1InitialValue, teamMembers : oldTeamMembersValue ?? D1InitialValue.teamMembers  });
      }
      if(isSelectedInternal) {
        eightDFormValues[MODULE_POSITION.D1] = ({ ...eightDFormValues[MODULE_POSITION.D1] ?? D1InitialValue, teamMembers : []  });
      }
    }
    if(currentPosition === MODULE_POSITION.D2) {
      updatedValue = {...form.values,  multiRecordUpdate : false  }
      eightDFormValues[MODULE_POSITION.FIVE_WHY] = ({ ...eightDFormValues?.[MODULE_POSITION.FIVE_WHY] ?? FiveWhyInitialValue, underStandingPoint : form.values.description})
    }
    if (currentPosition === MODULE_POSITION.FIVE_WHY) {
   
      eightDFormValues[MODULE_POSITION.D5_6] =  ({ ...eightDFormValues?.[MODULE_POSITION.D5_6] ?? D5InitialValue, correctiveAction: form.values.correctiveAction })
      eightDFormValues[MODULE_POSITION.D7] = {
        ...eightDFormValues?.[MODULE_POSITION.D7] ?? D7InitialValue,
        preventionAction: form.values.preventionAction,
      };
      eightDFormValues[MODULE_POSITION.D4] = {
        ...eightDFormValues?.[MODULE_POSITION.D4] ?? D4InitialValue,
        rootCause: `${form?.values?.fiveWhys?.map((fiveWhy: any) => fiveWhy.whatCause).join("\n")}\n${form.values.rootCause}`,
      };
    }
    if(currentPosition === MODULE_POSITION.D3) {
      updatedValue = ({ ...updatedValue ,  multiRecordUpdate : false  })
    }
    setEightDFormValue({ ...eightDFormValues, [currentPosition]: updatedValue, ...idObj });
    form.setValues(eightDFormValues?.[currentPosition + 1] ?? eightDForms[nextLabel].initialValue());
    setCurrentPosition(currentPosition + 1);
  };
  let goToPrevious = () => currentPosition - 1;
  var containmentView : boolean;
  const RenderForm = useCallback(() => {
    let Element:any = value.Component;
    if(state?.containmentApproved && (currentPosition === MODULE_POSITION.D0 || currentPosition === MODULE_POSITION.D1 || currentPosition===MODULE_POSITION.D2 || currentPosition === MODULE_POSITION.D3)){
      containmentView = true;
    }
    else{
      containmentView = view;
    }
    return <Box ><Element view={containmentView} audit8DLogId={audit8DLogId}  audit8dHeaderId={eightDFormValue.id} setEightDFormValue={setEightDFormValue} data={eightDFormValue} /></Box>;
     // eslint-disable-next-line
  }, [value]);

  const performSave = async (form: any, draft :boolean = false) => {
    let values = form.values;
    onOpenFullScreenLoader();
    switch (currentPosition) {
      case MODULE_POSITION.D0:
        PromiseResolve(await create8DPurpose({...D0BodyStructure(eightDFormValue, values), isDraft : draft}),form,  draft)
      break;
      case MODULE_POSITION.D1:
        PromiseResolve(create8DProblemSolving({...D1BodyStructure(eightDFormValue, values), isDraft : draft}),form,  draft)
        break;
      case MODULE_POSITION.D2:
        PromiseResolve(await create8DConcern({...D2BodyStructure(eightDFormValue, values), isDraft : draft}),form,  draft, isSelectedInternalPurpose)
        break;
      case MODULE_POSITION.D3:
        PromiseResolve(await create8DContainment({...D3BodyStructure(eightDFormValue, values), isDraft : draft}),form,  draft)
        break;
      case MODULE_POSITION.FIVE_WHY:
        PromiseResolve(await create8D5Whys({...FiveWhySaveBodyStructure(eightDFormValue, values), isDraft : draft}),form,  draft)
        break;
      case MODULE_POSITION.D4:
        PromiseResolve(await create8DRootCause({...D4BodyStructure(eightDFormValue, values), isDraft : draft}),form,  draft)
        break;
      case MODULE_POSITION.D5_6:
        PromiseResolve(await create8D_D5_D6({...D5D6BodyStructure(eightDFormValue, values), isDraft : draft}), form, draft);
        break;
      case MODULE_POSITION.D7:
        PromiseResolve(await create8D_D7({...D7BodyStructure(eightDFormValue, values), isDraft : draft, submit: draft ? false : true}), form, draft);
        break;
      case MODULE_POSITION.D8:
        PromiseResolve(await create8D_D8({...D8BodyStructure(eightDFormValue, values), isDraft : draft}), form, draft);
        break;
      default:
        goToNext(form);
        break;
    }
  };
  function Alert(type:any, alertMessage:string,alertLabel:string , onClickPrimary : () => void){
    setAlertProp({
      type: type,
      alertMessage,
      alertLabel,
      primaryButtonLabel: localeStrings("COMMON.CLOSE"),
      onClickPrimary
    });
    openAlert();
    return;
  }

  function PromiseResolve(callback:any,form:any, draft: boolean = false, save?: boolean){
    onCloseFullScreenLoader();
    if(callback?.response?.status === 400){
      Alert('confirmationError',callback?.response?.data?.title,  'Error', () => {
        closeAlert();
      });
      return;
    }
    else if(callback?.response?.status === 500){
      Alert('confirmationError',callback?.response?.data?.message,  'Error', () => {
        closeAlert();
      });
      return;
    }
    else if(callback.message){
      Alert('confirmationError',callback?.response?.data?.title,  'Error', () => {
        closeAlert();
      });
      return;
    }
   
    function findSuccessString () {
      switch(currentPosition){
          case MODULE_POSITION.D3:
            return localeStrings('ALERT.D3_SEND_REVIEW_MESSAGE');
          case MODULE_POSITION.D7:
            return localeStrings('ALERT.D7_SUBMIT_SUCCESS_MSG', { logID: callback.audit8DlogId ?? "8D" });
          case MODULE_POSITION.D8:
            if( form?.values?.customerSatisfication) return localeStrings('ALERT.CUSTOMER_APPROVE_LOG_SUCCESS', { logID: callback.audit8DlogId ?? `8D` } );
            return localeStrings('ALERT.CUSTOMER_REJECTED_LOG_SUCCESS', { logID: callback.audit8DlogId ?? `8D` }) ;
          default:
            return  `${callback.audit8DlogId ?? "8D"} has been saved successfully.`;
      }
    }
    if(save){
      Alert('success', findSuccessString(),  localeStrings("ALERT.SUBMITTED_SUCCES"),  () => {
        navigate("/8DLog");
        closeAlert();
      });
      return;
    }
    else{
      if(draft)
        navigate("/8DLog");
      else
        goToNext(form, callback.id, callback);
    }
  }

  const onClickNavigateBack = (form:any) => {
    if(!form.dirty){
      navigate("/8DLog");
      return;
    }
    setAlertProp({
      type: "confirmation",
      alertMessage: localeStrings("ALERT.ARE_YOU_SURE_CANCEL"),
      alertLabel: localeStrings("ALERT.CANCEL_CONFIRMATION"),
      secondaryButtonLabel: localeStrings("COMMON.NO_DONT"),
      primaryButtonLabel: localeStrings("COMMON.YES_CANCEL"),
      onClickPrimary: () => {
        navigate("/8DLog");
        closeAlert();
      },
      onClickSecondary: () => {
        closeAlert();
      },
    });
    openAlert();
  }
  const onSubmit = async (form: any) => {
    if(currentPosition === MODULE_POSITION.D3 && eightDFormValue.containmentApproved ){
      view = true;
    }
    if(view)  {
      performSave(form);
      setCurrentPosition(currentPosition + 1);
      return;
    }
    const errors = await form.validateForm();
    form.handleSubmit();
    let isValid = Object.keys(errors).length === 0;
    
    if (isValid) {
      let ModalConfirmation: AlertProps = {
        type: "confirmation",
        alertMessage: localeStrings("ALERT.SUBMIT_CFM_MESSAGE"),
        alertLabel: localeStrings("ALERT.SEND_CONFIRMATION"),
        secondaryButtonLabel: localeStrings("COMMON.NO_DONT"),
        primaryButtonLabel: localeStrings("COMMON.YES_SUBMIT"),
        onClickSecondary: () => {
          closeAlert();
        },
        onClickPrimary: () => {
        },
      };

      if(currentPosition === MODULE_POSITION.D8) {
        setAlertProp({
          ...ModalConfirmation,
          alertLabel: localeStrings('ALERT.SUBMIT_CONFIRMATION'),
          onClickPrimary: async() => {
            closeAlert();
            onOpenFullScreenLoader();
            PromiseResolve(await create8D_D8( {...D8BodyStructure(eightDFormValue, form.values), submit : true}), form, false, true);
            
          }
        });
        openAlert();
        return;
      }
      if(currentPosition === MODULE_POSITION.D7) {
        setAlertProp({
          ...ModalConfirmation,
        alertLabel: localeStrings("ALERT.SEND_CONFIRMATION"),
          onClickPrimary: async() => {
            closeAlert();
            onOpenFullScreenLoader();
            PromiseResolve(await create8D_D7( {...D7BodyStructure(eightDFormValue, form.values), submit : true}), form, false, true);
            
          }
        });
        openAlert();
        return;
      }
      if (currentPosition === MODULE_POSITION.D3 && !skipApprove) {
        setAlertProp({
          type: "confirmation",
          alertMessage: state?.containmentRejected ? localeStrings("ALERT.D3_REJECTED_REVIEW_CONFIRMATION_MESSAGE") : localeStrings('ALERT.D3_REVIEW_CONFIRMATION_MESSAGE'),
          alertLabel: state?.containmentRejected ?  localeStrings("ALERT.SEND_CONFIRMATION") : localeStrings('ALERT.SEND_FOR_REVIEW'),
          secondaryButtonLabel: state?.containmentRejected ? '' : localeStrings("COMMON.NO_DONT"),
          primaryButtonLabel: localeStrings("COMMON.YES_SEND"),
          onClickPrimary: async () => {
            closeAlert();
            onOpenFullScreenLoader();
            let res = await create8DContainment({ ...D3BodyStructure(eightDFormValue, form.values), review : true});
            if(res?.id){
              onCloseFullScreenLoader();
              Alert('success', localeStrings('ALERT.D3_SEND_REVIEW_MESSAGE'),  localeStrings('ALERT.D3_SEND_REVIEW_SUCCESS'),  () => {
                closeAlert();
                navigate("/8DLog");
              });
            }
            
          },
          onClickSecondary: () => {
            closeAlert();
            performSave(form);
            setSkipApprove(true);
          },
          closeIcon: state?.containmentRejected ? true : false
        });
        openAlert();
        return;
      }
      performSave(form);
    }
  };

  const onClickBack = (setValues: Function, values : any) => {
    setEightDFormValue({ ...eightDFormValue, [currentPosition]: values });
    setValues(eightDForms[preLabel].initialValue);
    setCurrentPosition(goToPrevious());
  };

  const onClickSaveDraft = async (form: any) => {
    if(currentPosition === MODULE_POSITION.D0) {
      let error = await validateDraft(form.values);
      if(Object.keys(error)?.length) {
        form.setErrors(error);
        form.setTouched({
          businessUnit : true,
          location : true
        });
      return;
      }
    }
    if(currentPosition === MODULE_POSITION.D1){
      performSave(form,true);
      return;
    }
    let checkCondition = (condition:boolean) => {
      
      
      if(condition) Alert('confirmationErrorModal',localeStrings("VALIDATORS.REQUIRED_ATLEAST_ONE_FIELD"),  'Error', () => {
        closeAlert();
      });
      else {
        if(currentPosition !== MODULE_POSITION.D1)
        performSave(form,true);
      };
      return;
    }
    switch (currentPosition) {
      case MODULE_POSITION.D0:
        return checkCondition(!checkAllowSaveDraft(form.values));
      case MODULE_POSITION.D1:
        return checkCondition(!checkAllowSaveDraft(form.values, "teamMembers"));
      case MODULE_POSITION.D2:
        return checkCondition(!checkAllowSaveDraft(form.values, "attachments"));
      case MODULE_POSITION.D3:
        return checkCondition(!checkAllowSaveDraft(form.values));
      case MODULE_POSITION.FIVE_WHY:
        return checkCondition(!checkAllowSaveDraft(form.values, "fiveWhys"));
      case MODULE_POSITION.D4:
        return checkCondition(!checkAllowSaveDraft(form.values));
      case MODULE_POSITION.D5_6:
        return checkCondition(!checkAllowSaveDraft(form.values));
      case MODULE_POSITION.D7:
        return checkCondition(!checkAllowSaveDraft(form.values));
      default:
        navigate("/8DLog");
        break;
    }
  };

  useEffect(() => {
    if(contentRef.current){
      const scrollBarDiv  = contentRef.current;
      scrollBarDiv.scrollTop = 0;
    }
  }, [currentPosition]);


  return (
    <Box  color="black" >
      <Formik
        enableReinitialize
        validationSchema={value.validationSchema ?? false}
        initialValues={eightDFormValue[currentPosition] ?? value.initialValue()}
        onSubmit={() => {}}
      >
        {(form) => (
          <Form>
            <Box bg="#EEF1F3">
            </Box>
            <Box display={["none","none","flex","flex"]} p="5"  minHeight={containerHeight} >
              <Box
                alignSelf="flex-start"
                height="auto"
                backgroundColor="white"
                p={2}
                pb={5}
                {...BoxShadowBorderRadius}
                width="20%"
              >
                    <Box cursor={'pointer'} pb={3} ml={5} mt={2} onClick={() =>onClickNavigateBack(form)} display={'inline-flex'} alignItems="center" ><span style={{display: 'flex',alignItems: "center",backgroundColor:'#0056be',borderRadius:"17px",padding:'3px'}}><ChevronLeft   style={{margin:"auto",color:'#FFFFFF',padding:'1px'}}/></span> <Text pl="2" fontSize={"var(--tk-fontSizes-xl)"} fontWeight={"var(--tk-fontWeights-semibold)"} >Create 8D</Text></Box>
                <SwitchTab style={{marginLeft:7}} tabs={progressLabels} currentPosition={currentPosition} steps={true} statusId={state ? state.statusId : 1} />
              
              </Box>
              <ScrollBar
                width={"80%"}
                {...BoxShadowBorderRadius}
                backgroundColor="white"
                padding={8}
                pt={4}
                ml={4}
                pb={0}
              >
                <RenderForm />
              </ScrollBar>
            </Box>
            <>
              <Box display={["flex","flex","none","none"]}>
              <ScrollBar
                height={MobileContainerHeight}
                width={'100%'}
                backgroundColor="white"
              >
                <>
                <MobilePageCountHeader 
                  currentLabel={progressLabels[currentPosition]}
                  currentPosition={currentPosition + 1}
                  total={progressLabels.length}
                />
                <Box padding={8} pb={0}>
                  <RenderForm />
                  </Box>
                
                </>
              </ScrollBar>
            </Box>
            </>
            <Flex
              bg="white"
              boxShadow={"0px 0px 6px #00000029"}
              alignItems="center"
              justifyContent="space-between"
              position={'sticky'}
              bottom={'0px'}
              zIndex={100}
            >
              {view === false && currentPosition !== MODULE_POSITION.D8  ? <Button
                data-testid="saveDraft"
                variant="outline"
                color="brand.primaryBrand"
                ml="4"
                disabled={containmentView}
                onClick={() => onClickSaveDraft(form)}
              >
                {localeStrings("CREATE_8D_LOG.SAVE_DRAFT")}
              </Button> :  <Box></Box>}
              <Box>
                {(currentPosition > 0 &&  currentPosition !== MODULE_POSITION.D8 ) && (
                  <Button
                    minHeight={"50px"}
                    bg={"#F1F5F8"}
                    borderRadius="0"
                    data-testid="previous"
                    onClick={() => onClickBack(form.setValues, form.values)}
                  >
                    <ArrowLeft style={{ marginRight: 10 }} />
                    {localeStrings("COMMON.PREVIOUS")}
                  </Button>
                )}
                <Button
                  data-testid="submit"
                  minHeight={"50px"}
                  bg={nextLabel === EIGHT_8D_SECTION.D8_CUST_FEEDBACK ||  currentPosition === MODULE_POSITION.D8 || (isSelectedInternalPurpose) ? "brand.primaryBrand":"#FFB400"}
                  color={nextLabel === EIGHT_8D_SECTION.D8_CUST_FEEDBACK || currentPosition === MODULE_POSITION.D8 || (isSelectedInternalPurpose) ? "white":"black"}
                  borderRadius="0"
                  onClick={() => onSubmit(form)}
                  disabled={view && currentWorkFlowStepId - 1 === currentPosition}
                >
                  {nextLabel === EIGHT_8D_SECTION.D8_CUST_FEEDBACK || currentPosition === MODULE_POSITION.D8 || (isSelectedInternalPurpose)  ? localeStrings("COMMON.SUBMIT") : localeStrings("COMMON.NEXT")}
                  <ArrowRight style={{ marginLeft: 10 }} />
                </Button>
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
}


const D0BodyStructure = (eightDFormValue: any, values: any) =>{ 
  return({
  currentWorkFlowStepId: MODULE_POSITION_API.D0,
  createdByUserId: "1",
  id: eightDFormValue.id ?? 0,
  multiRecordUpdate: values?.multiRecordUpdate ?? false,
  purpose8d: [
    {
      businessUnitId: parseInt(values?.businessUnit?.value) ?? null,
      locationId: parseInt(values?.location?.value)?? null,
      purposeId: parseInt(values?.purpose?.value) ?? null,
      customerName: values?.customerName,
      customerEmailId: values?.customerEmail,
      "defectCodeId": values?.defectCode?.value ? parseInt(values?.defectCode?.value) : null,
      "jobNumber": values?.jobNumber,
      otherDefectCode : values?.newDefectCode,
      purposeCustomerName : values?.customer
    },
  ]
})};

const D1BodyStructure = (eightDFormValue: any, values: any) =>{ 
  return({
  currentWorkFlowStepId: MODULE_POSITION_API.D1,
  createdByUserId: "1",
  id: eightDFormValue.id ?? 0,
  multiRecordUpdate: values?.multiRecordUpdate ?? false,
  problemSolvingTeam : [
    {
      championTitle: values?.championTitle,
      championName: values?.championName,
      phoneNumber: values?.championPhone,
    }
  ],
  teammemberlist: values?.teamMembers,
})};

const D2BodyStructure = (eightDFormValue: any, values: any) => ({
  id: eightDFormValue.id,
  currentWorkFlowStepId:  MODULE_POSITION_API.D2,
  createdByUserId: "1",
  multiRecordUpdate: values?.multiRecordUpdate ?? false,
  concern8d: [
    {
      jobNumber: values.jobNumber,
      concernDescription: values.description,
    },
  ],
  files : values.attachments
});
const D3BodyStructure = (eightDFormValue: any, values: any) => ({
  id: eightDFormValue.id,
  currentWorkFlowStepId:  MODULE_POSITION_API.D3,
  createdByUserId: "1",
  containment8d: [
    {
      containmentDescription: values.d3Description,
    },
  ],
});

const FiveWhySaveBodyStructure = (eightDFormValue: any, values: any) => ({
  id: eightDFormValue.id,
  currentWorkFlowStepId:  MODULE_POSITION_API.FIVE_WHY,
  createdByUserId: "1",
  multiRecordUpdate: values?.multiRecordUpdate ?? false,
  audit5Why8d: [
    {
      cellOrEquipment: values.writeCellOrEquipment,
      correctiveAction: values.correctiveAction,
      preventiveAction: values.preventionAction,
      pointOfCause: values.underStandingPoint,
      rootCause: values.rootCause,
    },
  ],
  audit5WhyRootCause: values?.fiveWhys?.length ? values.fiveWhys : [],
});

const D4BodyStructure = (eightDFormValue: any, values: any) => ({
  id: eightDFormValue.id,
  currentWorkFlowStepId:  MODULE_POSITION_API.D4,
  createdByUserId: "1",
  rootCause8d: [
    {
      rootCause: values.rootCause,
    },
  ],
});

const D5D6BodyStructure = (eightDFormValue: any, values: any) => {
  const formatDate = (date: any) =>
    moment(date).toISOString().includes("T18:30:00.000Z")
      ? new Date(moment(date).add(1, "day").toISOString())
      : moment(date).toISOString();

  return {
    id: eightDFormValue.id,
    currentWorkFlowStepId: MODULE_POSITION_API.D5_6,
    createdByUserId: "1",
    correctiveAction8d: [
      {
        correctiveAction: values.correctiveAction,
        targetDate: values.targetDate ? formatDate(values.targetDate) : null,
        followUpDate: values.followUpDate ? formatDate(values.followUpDate) : null,
      },
    ],
    responsiblePersons: values.responsiblePerson.map((i:any) =>
      ({
        responsiblePerson: i.label,
        emailId: i.value
      })
    ),
  };
};

const D7BodyStructure = (eightDFormValue: any, values: any) => ({
  id: eightDFormValue.id,
  currentWorkFlowStepId:  MODULE_POSITION_API.D7,
  createdByUserId: "1",
  submit : true,  
  preventiveAction8d: [
    {
      preventiveAction: values.preventionAction !== "" ? values.preventionAction  : null,
      approverId: values.approver !== "" ? values.approver : null,
      costOfQuality: values.costOfQuality !== "" ? values.costOfQuality : null
    }
  ]
});


const D8BodyStructure = (eightDFormValue: any, values: any) => ({

  files: values.attachement,
  id: eightDFormValue.id,
  currentWorkFlowStepId:  MODULE_POSITION_API.D8,
  createdByUserId: "1",
  feedback8d: [
    {
      CustomerSatisfaction:  values.customerSatisfication ? 1 : 0,
      CustomerNotes: values.customerNotes
    }
  ]
});


export default function CreateChild8D( { isChild, position = 0, fillFiveWhy = false, fill8D = false } : { isChild : boolean; position?: number; fillFiveWhy?: boolean; fill8D?: boolean}) {
  const { id } = useParams();
  const { accounts } = useMsal();
  const [data, setData] = useState({ currentWorkFlowStepId : 0, bindValue : {}, id:null , statusId : null, isContainmentApproved  : false, audit8DLogId : null, isCurrentUser : false });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch8DData(`${id}`).then((data) => {
      setIsLoading(false);
      let obj = {
        [MODULE_POSITION.FIVE_WHY] : {
          ...FiveWhyInitialValue
        }
      };
      if(data?.d2_concern?.[0]?.concernDescription) {
        obj = {
          ...obj,
          [ MODULE_POSITION.FIVE_WHY]: {
            ...FiveWhyInitialValue,
            underStandingPoint: data.d2_concern[0].concernDescription, 
          }
        }
        
      }
      let bindValue = {
        [MODULE_POSITION.D0]: data.currentWorkFlowStepId >= MODULE_POSITION_API.D0 ? {
          ...data.d1_purpose[0],
          id : data.id,
          businessUnit: { value: data.d1_purpose[0].businessUnitId },
          title: data.d1_purpose[0].purposeTitle,
          location : { value: data.d1_purpose[0].locationId },
          customerName : data.d1_purpose[0].customerName,
          purpose :  { value: data.d1_purpose[0].purposeId },
          customerEmail : data.d1_purpose[0].customerEmailId,
          customer : data.d1_purpose[0].purposeCustomerName,
          defectCode : { label : data.d1_purpose[0].defectCodeName, value: parseInt(data.d1_purpose[0].defectCodeId)},
          jobNumber : data.d1_purpose[0].d0JobNumber,
          newDefectCode : ""
        } : null,
        [MODULE_POSITION.D1]: data.currentWorkFlowStepId >= MODULE_POSITION_API.D1 ? {
          ...data.d1_problemsolvingTeam[0],
          id : data.id,
          championTitle: data.d1_problemsolvingTeam[0].championTitle,
          championName: data.d1_problemsolvingTeam[0].championName,
          championPhone: data.d1_problemsolvingTeam[0].phoneNumber,
          teamMembers: data.d1_problemsolvingTeam[0].teammemberlist?.length ? data.d1_problemsolvingTeam[0].teammemberlist.map((i:any) => {
            return ({
              ...i,
              teamMemberTitle : i.teamMemberTitle ?? "", 
              teamMemberName: i.teamMemberName ?? "", 
              phoneNumber: i.phoneNumber ?? ""
            })
          })
           :   data?.createdUserEmailId === accounts[0]?.username && data.d1_purpose[0].purposeId === 1 ? []: D1InitialValue.teamMembers,
        } : null,
        [MODULE_POSITION.D2]: data.currentWorkFlowStepId >= MODULE_POSITION_API.D2 ? {
          id : data.id,
          jobNumber: data.d1_purpose[0].d0JobNumber,
          description: data.d2_concern[0].concernDescription,
          attachments: data.d2_concern[0]?.audit8DConcernAttachment?.map((e:any)=>{return {data_url:e?.fileSasTokenUrl, Id:e?.id, audit8DLogId:data?.id, ...e}}) ?? []
        } : null,
       [ MODULE_POSITION.D3]: data.currentWorkFlowStepId >= MODULE_POSITION_API.D3 ? {
          id : data.id,
          d3Description : data.d3_containment[0].containmentDescription
        } : null,
       [ MODULE_POSITION.FIVE_WHY]:  data.currentWorkFlowStepId >= MODULE_POSITION_API.FIVE_WHY ?{
          id : data.id,
          ...obj?.[MODULE_POSITION.FIVE_WHY],
          writeCellOrEquipment: data.d5_why[0].cellOrEquipment,
          correctiveAction: data.d5_why[0].correctiveAction  ,
          underStandingPoint: data.d2_concern[0].concernDescription, 
          rootCause: data.d5_why[0].rootCause,
          fiveWhys : data.d5_why[0].audit5WhyRootCause,
          preventionAction:  data.d5_why[0].preventiveAction
        } : obj?.[MODULE_POSITION.FIVE_WHY],
        [MODULE_POSITION.D4]: data.currentWorkFlowStepId >= MODULE_POSITION_API.FIVE_WHY ?{
          id : data.id,
          rootCause :`${data?.d5_why?.[0]?.audit5WhyRootCause.map((fiveWhy: any) => fiveWhy.whatCause).join("\n")}\n${data?.d5_why?.[0]?.rootCause ?? ""}`,
        } : null,
        [MODULE_POSITION.D5_6]:    data.currentWorkFlowStepId >= MODULE_POSITION_API.D5_6 ?{
          id : data.id,
          businessUnit: data.d1_purpose[0].businessUnitId,
          location : data.d1_purpose[0].locationId,
          correctiveAction: data.d5_6_corrective_action[0].correctiveAction ?? data.d5_why[0].correctiveAction ?? "",
          responsiblePerson : data.d5_6_corrective_action[0]?.responsiblePersonList?.length ? data.d5_6_corrective_action[0].responsiblePersonList.map((i:any) => ({ value : i.emailId, label : i.responsiblePerson})) : [],
          targetDate :  data.d5_6_corrective_action[0]?.targetDate?.slice(0, 10) ?? "",
          followUpDate : data.d5_6_corrective_action[0]?.followUpDate?.slice(0, 10) ?? ""
      } : null,
        [MODULE_POSITION.D7]:   data.currentWorkFlowStepId >=  MODULE_POSITION_API.D7 ?{
          id : data.id,
          preventionAction: data.d7_preventive_action[0]?.preventiveAction ?? data.d5_why[0].preventiveAction ??  data.d5_why[0].preventiveAction ?? "",
          approver: data.d7_preventive_action[0]?.approverId ?? "",
          costOfQuality: data.d7_preventive_action[0]?.costOfQuality ?? ""
        } : null,
        [MODULE_POSITION.D8]: data.currentWorkFlowStepId >=  MODULE_POSITION_API.D8 ?{
          id : data.id,
          ...D8FeedBackInitialValue
        } : null
      };
      if(data.currentWorkFlowStepId !== MODULE_POSITION_API.D8) {
      validateDraftDetails(bindValue, data.currentWorkFlowStepId ?? 0).then((formPosition) => {
        setData({
          currentWorkFlowStepId : formPosition + 1,
          id:data.id,
          statusId : data.statusId,
          isContainmentApproved : data?.approvedby ? true : false,
          bindValue : bindValue,
          audit8DLogId: data.audit8DLogId,
          isCurrentUser : data?.createdUserEmailId === accounts[0]?.username
      });
    });
    }
    else {
      setData({
        currentWorkFlowStepId : data.currentWorkFlowStepId,
        id:data.id,
        statusId : data.statusId,
        isContainmentApproved : data?.approvedby ? true : false,
        bindValue : bindValue,
        audit8DLogId : data.audit8DLogId,
        isCurrentUser : data?.createdUserEmailId === accounts[0]?.username
    });
    }
    }).catch(err => {
      console.log(err);
      
    })
    // eslint-disable-next-line
  }, [id]);

  if (isLoading) return <Flex alignItems={"center"} justifyContent="center" height={containerHeight}><BrandSpinner /></Flex>;
  return <Create8D 
    currentposition={ isChild  ? position :  fillFiveWhy ? MODULE_POSITION.FIVE_WHY :  fill8D ? MODULE_POSITION.D8  : data.currentWorkFlowStepId - 1} 
    currentWorkFlowStepId={data.currentWorkFlowStepId } 
    state={{...data.bindValue,id:data.id,
      containmentApproved: fillFiveWhy ? true : data.isContainmentApproved,
      containmentRejected:data.statusId === LOG_STATUS.CONTAINMENT_REVIEW_REJECTED,
      audit8DLogId : data.audit8DLogId,
      isCurrentUser : data.isCurrentUser
    }} 
  />;
}