import API_ENDPOINT from "./endpoints";
import { azureFetcher } from "./fetcher";

export async function FetchUserList({
  businessunitId,
  locationid,
}: {
  businessunitId: number;
  locationid: number;
}) {
  let filter:string[] = [];
  if(businessunitId) {
    filter.push(`businessunitId=${businessunitId}`);
  }
  if(locationid){
    filter.push(`locationid=${locationid}`);
  }
  return await azureFetcher.get(
    `${API_ENDPOINT.GET_USER_LIST}${filter?.length ? `?${filter.join("&")}` : ''}`
  );
}

export async function FetchUserListAcrossBusinessUnit({
  businessunitId,
  locationid,
}: {
  businessunitId: number;
  locationid: number;
}) {
  let filter:string[] = [];
  if(businessunitId) {
    filter.push(`businessunitId=${businessunitId}`);
  }
  if(locationid){
    filter.push(`locationid=${locationid}`);
  }
  return await azureFetcher.get(
    `${API_ENDPOINT.GET_ALL_AUDITOR_NAME}${filter?.length ? `?${filter.join("&")}` : ''}`
  );
}