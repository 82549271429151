import { Box, BoxProps } from "@chakra-ui/react";
import { forwardRef } from "react";

function ScrollBar({ children, ...rest }: { children: JSX.Element } & BoxProps, ref :any) {
  return (
    <Box
      ref={ref}
      overflowY="auto"
      {...rest}
    >
     
      {children}
    </Box>
  );
}

export default forwardRef(ScrollBar);