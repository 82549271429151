import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
} from "@chakra-ui/react";
import { useField } from "formik";
import { CheckBoxPropType } from "./FormComponent";
import Label from "./label";

export const CheckBox = ({
  label,
  name,
  error,
  touched,
  placeholder,
  required,
  labelStyle,
  thumbnailImages,
  options,
  ...rest
}: CheckBoxPropType) => {
  return (
    <FormControl isInvalid={error && touched ? true : false} isRequired={required}>
      {/* <FormLabel {...labelStyle} htmlFor={name}>
        {label}
      </FormLabel> */}
      {label && <Label name={name} labelStyle={labelStyle} label={label} thumbnailImages={thumbnailImages} />}
      <CheckboxGroup colorScheme="green" isDisabled={rest?.disabled ?? false} {...rest}>
        <Stack spacing={[1, 5]} direction={"column"}>
          {options?.map((option, index) => (
            <Checkbox
              style={{ color: "black" }}
              key={`${index}_${option.value}`}
              data-testid={`${name}_${option.value}`}
              value={option.value}
              colorScheme='blue'
            >
              {option.label}
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
      <FormErrorMessage as="div">{error}</FormErrorMessage>
    </FormControl>
  );
};

export function CheckBoxFormControl({ name, ...rest }: CheckBoxPropType) {
    const [field, { error, touched }, helper] = useField(name);
    return <CheckBox {...rest} {...field} error={error} touched={touched} onChange={(e) => {helper.setValue(e);}} />;
}
  