import { Button as Buttons, ButtonProps } from '@chakra-ui/react';

export enum BUTTON_TYPE  {
  WHITE  = "white",
 GREY = "grey",
  PRIMARY = "primary",
  SECONDARYONE ="secondaryOne",
  SECONDARYTWO = "secondaryTwo",
  GREEN = "green",
  RED = "red",
  TAB = 'tab',
  TABTWO = 'tabTwo',
  SECONDARYTHREE = 'secondaryThree'
}
export type buttonTypes = {
  buttontype?: BUTTON_TYPE;
}
& ButtonProps;

export default function Button({children, buttontype, ...rest}: buttonTypes) {
  let style = {};
  if(buttontype === BUTTON_TYPE.WHITE){
    style = {
      color : "black",
      bg : "white",
    }
  }
  if(buttontype === BUTTON_TYPE.GREY){
    style = {
      color : "white",
      bg : "#4B5564",
      _hover : {     },
    }
  }
  if(buttontype === BUTTON_TYPE.PRIMARY){
    style = {
      color : "black",
      bg : "#FFB400",
      _hover : { bg: "#F7AE00" },
    }
  }
  if(buttontype === BUTTON_TYPE.SECONDARYONE){
    style = {
      color : "white",
      bg : "#0078DC",
      _hover : { bg: "#0078DC" }
    }
  }
  if(buttontype === BUTTON_TYPE.SECONDARYTWO){
    style = {
      color : "#003C7D",
      bg : "#003c7d1a",
      _hover : { bg: "#003c7d2a" }
    }
  }
  if(buttontype === BUTTON_TYPE.GREEN){
    style = {
      color : "brand.turquoise",
      bg : "#0F968C1a",
      _hover : { bg: "#0F968C1a" }
    }
  }
  if(buttontype === BUTTON_TYPE.RED){
    style = {
      color : "brand.red",
      bg : "#E600501a",
      _hover : { bg: "#E600501a" }
    }
  }
  if(buttontype === BUTTON_TYPE.TAB){
    style = {
      color : "brand.grayMedium",
      border: '1px solid',
      borderColor:'brand.grayLight',
      backgroundColor : "#F9FAFB",
      _hover : { bg: "#F9FAFB" }
    }
  }
  if(buttontype === BUTTON_TYPE.TABTWO){
    style = {
      color : "#0055BE",
      border: '1px solid',
      borderColor:'#0055BE',
      borderBottom: '0px',
      backgroundColor : "#F9FAFB",
      _hover : { bg: "#F9FAFB" }
    }
  }
  if(buttontype === BUTTON_TYPE.SECONDARYTHREE){
    style = {
      color : "brand.white",
      border: '1px solid',
      borderColor:'#0055BE',
      backgroundColor : "#0055BE",
      _hover : { bg: "#0055BE" }
    }
  }
  return (
    <Buttons {...style} className={`button`} {...rest} isDisabled={rest.disabled}>{children}</Buttons>
  )
}