import { Box, Flex,  FormControl,  FormLabel, IconButton, Image, Text, useDisclosure, Tooltip,   Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton } from "@chakra-ui/react";
import { useState, useCallback } from 'react';
import { XCircleFill, ZoomIn, ZoomOut } from "react-bootstrap-icons";
import ImageUploading from "react-images-uploading";
import { imageUploader } from "./FormComponent";
import { images } from "../../assets";
import { useField } from "formik";
import { deleteAttachment } from "../../api/create8D";
import { DOC_TYPES, FILE_TYPE } from "../../utils/const";
import { deleteAttachmentAudit } from "../../api/performAudit";
import { useFullScreenLoader } from "../../providers/FullScreenLoaderProvider";
import { azureFetcher } from "../../api/fetcher";
import API_ENDPOINT from "../../api/endpoints";
import { useAlertContext } from "../../providers/AlertProvider";
import { localeStrings } from "../../utils/i18n";
import {InfoOutlineIcon} from '@chakra-ui/icons';
import Label from "./label";
const maxNumber = 30;

export type fileExtrasType = {
  audit8DHeaderId: number,
  originalFileName: string,
  filePathUrl: string,
  uniqueFileName: string
};

export type pickedFileResponseType = {
  id?: string,
  uri: string,
  name: string | null,
  copyError?: string,
  fileCopyUri: string | null,
  type: string | null,
  size: number | null,
  extraInfo?: fileExtrasType,
  fileName?: string
};


export default function ImageUploader({
  label,
  name,
  error,
  touched,
  responsive,
  onChange = () => {},
  value,
  disabled,
  labelStyle,
  width,
  isPerformAudit,
  isQuestionLevelFile,
  thumbnailImages,
  audit8dHeaderId,
  supportedFileTypes,
  ...rest
}: imageUploader) {
  const [imageUrl,setImageUrl] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const  { onOpenFullScreenLoader, onCloseFullScreenLoader} = useFullScreenLoader();
  const { openAlert, setAlertProp, closeAlert } = useAlertContext();

  const uploadMediaToServer = useCallback(async (selectedItems: any[] = []) => {
    onOpenFullScreenLoader();    
    const copyDetails: pickedFileResponseType[] = Array.from(selectedItems);
    for (let itemIndex = 0; itemIndex < selectedItems.length; itemIndex++) {
        const targetFile = selectedItems[itemIndex];
        if(!targetFile?.id &&  !targetFile?.extraInfo){
        const fileData = new FormData();
        if (!isPerformAudit) {
          fileData.append("Audit8DHeaderId", `${audit8dHeaderId}`);
        }
        fileData.append("PrimaryModule", isPerformAudit ? FILE_TYPE.AUDIT : FILE_TYPE.EIGHT_D);
        fileData.append("Files", targetFile.file);
        try {
          const response = await azureFetcher.post(API_ENDPOINT.FILE_UPLOAD, fileData, {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  "Accept": 'application/json'
              }
          });
          copyDetails[itemIndex].extraInfo = response.data ? {...response.data,fileUrl : response.data.filePathUrl} : {};
          copyDetails[itemIndex].fileName = response.data.originalFileName;
      } catch (e) {
          console.log("error while upload file", e);
      }
      }
    }

    onChange(copyDetails);
    onCloseFullScreenLoader();
}, []);

  function deleteImages({onImageRemove, Id, headerId, index}:{onImageRemove:any; Id:any; headerId:number; index:number}){
    if(isPerformAudit){
      if(Id){
        onOpenFullScreenLoader();
        deleteAttachmentAudit({
          fileId : Id,
          fileScope: isQuestionLevelFile ? "RecordFile" : "AuditFile"
        }).then((e)=>{
          onCloseFullScreenLoader();
          if(e === 200)
            onImageRemove(index);
            const updatedValue = value.filter((item:any,i:number) => i !== index);
            onChange(updatedValue);
        }).catch(() =>  onCloseFullScreenLoader());
        
      }
      else{
        onImageRemove(index);
        const updatedValue = value.filter((item:any,i:number) => i !== index);
        
        onChange(updatedValue);
      }
    }
    else {
      if(Id){
        onOpenFullScreenLoader();
        deleteAttachment({audit8DHeaderId:headerId, Id:Id}).then((e)=>{
          onCloseFullScreenLoader();
          if(e === 200)
            onImageRemove(index);
            const updatedValue = value.filter((item:any,i:number) => i !== index);
            onChange(updatedValue);
        }).catch(() =>  onCloseFullScreenLoader());
      }
      else{
        onImageRemove(index);
        const updatedValue = value.filter((item:any,i:number) => i !== index);
        
        onChange(updatedValue);
      }
    }
  }
  
 
  function deleteAlert({onImageRemove, Id, headerId, index}:{onImageRemove:any; Id:any; headerId:number; index:number}){
    setAlertProp({
      type: "confirmation",
      alertMessage: localeStrings("ALERT.ATTACHMENT_DELETE_CONFIRMATION"),
      alertLabel: localeStrings("ALERT.DELETE_CONFIRMATION"),
      secondaryButtonLabel: localeStrings("COMMON.YES_DELETE"),
      primaryButtonLabel: localeStrings("COMMON.NO_DONT"),
      onClickPrimary: () => {
        closeAlert();
      },
      onClickSecondary: () => {  
        deleteImages({onImageRemove,Id,headerId,index});
        closeAlert();
      },
    });
    openAlert();
    return;
  }

  return (
    <FormControl isRequired={rest.required ?? false}>
    <ImageUploading
      multiple
      value={value}
      onChange={(e, addUpdateIndex  = []) => {
        let images:any[] = value ?? [];
        if(addUpdateIndex.length === 1 && !images.map(item => item?.file?.name ?? item?.fileName ?? "").includes(e[addUpdateIndex[0]].file?.name)) {
          uploadMediaToServer(e);
        }
        if(addUpdateIndex.length > 1) {
          let fileNames = images.map(item => item?.file?.name ?? item?.fileName ?? "");
          let updateItem = addUpdateIndex.filter(index => fileNames.includes(e[index].file?.name));
          uploadMediaToServer(e.filter((image, index) => !updateItem.includes(index)));
        }
      }}
      maxNumber={maxNumber}
      maxFileSize={1048576}
      dataURLKey="data_url"
      allowNonImageType={true}
      {...rest}
      acceptType={supportedFileTypes ?? ["jpg", "png", "gif", "txt", "docx", "doc", "pdf", "xlsx", "mp4", "csv", "xls","heic"]}
    >
      {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps, errors }) => (
        <>
          {/* <FormLabel {...labelStyle} htmlFor={name}>{label}</FormLabel> */}
          {label && <Label name={name} labelStyle={labelStyle} label={label} thumbnailImages={thumbnailImages} />}
          {!disabled && <Flex
            id={name}
            as="button"
            border={(errors?.maxFileSize || errors?.acceptType) ? "2px dashed red" : "1px dashed #D9DEE8"}
            borderRadius="5px"
            h="98px"
            w={width ?? ["100%", "70%", "50%", "40%"]}
            style={isDragging ? { color: "red" } : undefined}
            onClick={onImageUpload}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            disabled={disabled}
            {...rest}
            {...dragProps}
          >
            <img src={images.create8D.uploadImagePlaceHolder} alt="drag and drop icon" />
            <Box display="flex" pt="3" justifyContent={"center"} alignItems="center">
              {" "}
              Drop your files here or&nbsp;<Text color="brand.primaryBrand">Browse</Text>
              <Tooltip hasArrow label={`Supported file types ${supportedFileTypes?.length ? [supportedFileTypes[0], ...supportedFileTypes.slice(1).map((type:any) => ` ${type}`)] : ["jpg", " png", " gif", " txt", " docx", " doc", " pdf", " xlsx", " mp4", " csv", " xls"," heic"].join(", ")}`} openDelay={200} p={2} color='var(--tk-colors-white)' placement="right">
                <InfoOutlineIcon ml={2} w={3} h={3} display={ 'block'} color={'var(--tk-colors-brand-grayMedium)'} />
              </Tooltip>
            </Box>
          </Flex>}
          <Box color={"red.500"}>{errors?.maxFileSize && "The selected file size exceeds 1 MB. Please attach a file less than 1 MB."}</Box>
          <Box color={"red.500"}>{errors?.acceptType && `Please select the supported file formats. ${supportedFileTypes?.length ? [supportedFileTypes[0], ...supportedFileTypes.slice(1).map((type:any) => ` ${type}`)] : ["jpg", " png", " gif", " txt", " docx", " doc", " pdf", " xlsx", " mp4", " csv", " xls"," heic"].join(", ")}`}</Box>
          {touched && error && <Box color={"red.500"}>{error}</Box>}
          <Flex flexWrap="wrap">
            {imageList.map((image, index) => {
              let Id = image?.Id ?? image?.id;
              let headerId = image?.audit8DLogId;
              let fileType = image["data_url"]?.split('.')
              fileType = fileType[fileType.length-1]?.split('?')?.[0];
              if(fileType.includes('csv') && fileType?.length < 40){
                fileType = 'csv'
              }
              if(image?.fileName) {
                fileType =  image.fileName.split('.')?.[1];
              }
                
              return (
                <Box key={Id} position="relative" width={67} height={67} display="flex" alignItems="center" justifyContent={"center"} textAlign="center" p="2" m="2" border="1px solid #D9DEE8" borderRadius="8px">
                  {image?.file?.type.includes("application") || (DOC_TYPES.includes(fileType))  ? (
                    <a href={image["data_url"]} download>
                      <Image src={fileType === 'pdf' ? images.create8D.pdf : fileType === 'heic' ? images.create8D.heic : fileType === 'doc' ? images.create8D.doc : (fileType === 'xls' || fileType === 'xlsx' || fileType ==='excel') ? images.create8D.excel : images.create8D.doc} alt=""  width="100%" height={"100%"}/>
                    </a>
                  ) : (
                      <Image onClick={()=>{setImageUrl(image["data_url"]);onOpen();}} src={image["data_url"]} alt="" width="100%"  height={"100%"}/> 
                  )}
                  {!disabled ? <Box onClick={() => { deleteAlert({onImageRemove,Id,headerId,index}) }} cursor="pointer" position="absolute" right={-2} top={0}>
                    <XCircleFill fill="#ffafaf" />
                  </Box> : <></>}
                </Box>
              );
            })}
          </Flex>
        </>
      )}
    </ImageUploading>
    <Box >
     {isOpen && <ViewImage imageUrl={imageUrl} setImageUrl={setImageUrl} onClose={onClose}  isOpen={isOpen} />}
    </Box>
    </FormControl>
  );
}

export function ImageUploaderFormControl({ name,onChange, ...rest }: imageUploader) {
  const [field, { error, touched }, helper ] = useField(name);

  return <ImageUploader {...rest} {...field}  error={error} touched={touched} onChange={(e) => { helper.setValue(e); if(onChange) onChange(e); }}  />;
}


export function ViewImage({ imageUrl, onClose, isOpen, setImageUrl }: { imageUrl: string; onClose : () => void; setImageUrl : (a:any) => void; isOpen : boolean }) {
  const [zoom, setZoom] = useState(100);

  return (
    <Modal
      isCentered={true}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setZoom(100);
        setImageUrl("");
      }}
    >
      <ModalOverlay />
      <ModalContent display={"grid"} placeItems={"center"} p={0} position="revert">
        <ModalCloseButton p={4} m={4} bg={"gray.100"} color={"black"} right={0} top={0} />
          <Flex position={"absolute"} top={4} zIndex="9999">
            <IconButton
              aria-label="ZoomOut"
              onClick={() => {
                if (zoom > 100) setZoom(zoom - 50);
              }}
              h={8}
              w={8}
              mr={6}
              icon={<ZoomOut />}
            />
            <IconButton
              aria-label="ZoomIn"
              onClick={() => {
                if (zoom < 200) setZoom(zoom + 50);
              }}
              h={8}
              w={8}
              icon={<ZoomIn />}
            />
          </Flex>
        <ModalBody width={`${zoom}%`} p={0} position={"relative"} zIndex="-1">
            <img alt={"Attachment"} src={imageUrl} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}