import { Box, Text, Image, Flex } from '@chakra-ui/react';
import React from 'react'
import { images } from '../../../assets';
import { DOC_TYPES } from '../../../utils/const';
import { localeStrings } from '../../../utils/i18n';

export default function ThumbnailView({Images, setImageUrl, OnOpen}:{
    Images : Array<Object>;
    setImageUrl : any;
    OnOpen : ()=>void;
}) {
    const filetypes = ['excel','pdf','xls','xlxs','doc','heic','csv'];
    const [filesList, setFilesList] = React.useState([]);

    React.useEffect(()=>{
        if(Images?.length){
            let sortedImages :any = Images.sort((a:any, b:any) => {
                const extA = a.fileName.split('.').pop().toLowerCase();
                const extB = b.fileName.split('.').pop().toLowerCase();
                const indexA = filetypes.indexOf(extA);
                const indexB = filetypes.indexOf(extB);
                const aInFileType = indexA !== -1;
                const bInFileType = indexB !== -1;
                if (!aInFileType && bInFileType) return -1;
                if (aInFileType && !bInFileType) return 1;
                if (aInFileType && bInFileType) return indexA - indexB;
                return 0;
            });
            setFilesList(sortedImages);
        }
    },[Images]);
  return (
    <>
    {filesList?.length ? <Box>
        <Text color={'brand.grayMedium'}>{localeStrings("COMMON.REFERENCE_DOCUMENTS")}</Text>
            <Flex flexWrap="wrap" alignItems={'end'}>
                {filesList.map((image :any, index: number) => {
                let Id = image?.Id ?? image?.id;
                let fileType = image["fileName"]?.split('.')
                fileType = fileType[fileType.length-1]?.split('?')?.[0];
                if(fileType.includes('csv') && fileType?.length < 40){
                    fileType = 'csv'
                }
                if(image?.fileName) {
                    fileType =  image.fileName.split('.')?.[1];
                }
                let isImage = image?.file?.type.includes("application") || (DOC_TYPES.includes(fileType)) ? false : true
                    
                return (
                    <Box key={Id} position="relative" width={isImage ? 130 : 67} height={isImage ? 130 : 67} display="flex" alignItems="center" justifyContent={"center"} textAlign="center" p="2" m="2" border="1px solid #D9DEE8" borderRadius="8px">
                    {image?.file?.type.includes("application") || (DOC_TYPES.includes(fileType))  ? (
                        <a href={image["sasUrl"]} download>
                        <Image src={fileType === 'pdf' ? images.create8D.pdf : fileType === 'heic' ? images.create8D.heic : fileType === 'doc' ? images.create8D.doc : (fileType === 'xls' || fileType === 'xlsx' || fileType ==='excel') ? images.create8D.excel : images.create8D.doc} alt=""  width="100%" height={"100%"}/>
                        </a>
                    ) : (
                        <Image onClick={()=>{setImageUrl(image["sasUrl"]);OnOpen();}} src={image["sasUrl"]} alt="" width="100%"  height={"100%"}/> 
                    )}
                    </Box>
                );
                })}
            </Flex>
        </Box> : <></>}
    </>
  )
}
