export const D0_PREPARE_8D_INITIAL_VALUE = {
  businessUnit: "",
  location: "",
  purpose: "",
  defectCode : "",
  jobNumber : "",
  customerName: "",
  customerEmail: "",
  customer: "",
  newDefectCode : ""
}

export const D1InitialValue = {
  championTitle: "",
  championName: "",
  championPhone: "",
  teamMembers: [{ teamMemberTitle : "", teamMemberName: "", phoneNumber: "" }],
};

export const D2InitialValue = {
  jobNumber: "",
  attachments: [],
  description: "",
};

export const D3InitialValue = {
  d3Description: "",
};

export const FiveWhyInitialValue = {
  writeCellOrEquipment : '',
  underStandingPoint : "",
  fiveWhys : [{serialNo:1, whatCause:"", whatFailed:"", whyProblem:""},{ serialNo:2, whatCause: "", whatFailed:"", whyProblem:""},{ serialNo:3, whatCause: "", whatFailed:"", whyProblem:""}],
  rootCause : "",
  correctiveAction : "",
  preventionAction: ""
};

export const D4InitialValue = {
  rootCause: "",
};

export const D5InitialValue = {
  responsiblePerson: [],
  targetDate: "",
  followUpDate: "",
  correctiveAction: ""
};

export const D7InitialValue = {
  preventionAction: "",
  costOfQuality: "",
};

export const D8FeedBackInitialValue = {
  customerSatisfication: undefined,
  customerNotes: '',
  attachement: undefined
};