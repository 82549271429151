import { Box, useDisclosure } from "@chakra-ui/react";
import {createContext,useContext,ReactNode } from "react";
import styled from "@emotion/styled";

import { BrandSpinner } from "./../Component/ui/BrandSpinner";

const FullScreenLoaderContext = createContext<any>({});

export const useFullScreenLoader = () => {
  const data: {
    onOpenFullScreenLoader: Function;
    onCloseFullScreenLoader: Function;
  } = useContext(FullScreenLoaderContext);
  return data;
};
export default function FullScreenLoaderContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <FullScreenLoaderContext.Provider value={{ onOpenFullScreenLoader:onOpen, onCloseFullScreenLoader:onClose }}>
      {children}
      {isOpen && (
        <Box as={OverLay} bg={"blackAlpha.600"} data-testid="loading-spinner"  >
          <BrandSpinner />
        </Box>
      )}
    </FullScreenLoaderContext.Provider>
  );
}

const OverLay = styled.div(() => ({
  position: "fixed",
  zIndex: 100000,
  top: 0,
  left: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  overflow : "hidden"
}));
