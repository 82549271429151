import { addMethod, object,string,array, date,boolean } from "yup";
import { localeStrings } from "../../utils/i18n";
addMethod(object, "unique", function (propertyName, message) {
  return this.test("unique", message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    if (
      this.parent
        .filter((v: any) => v !== value)
        .some((v: any) => {
          if (v[propertyName] && value[propertyName]) {
            return v[propertyName].toLowerCase() === value[propertyName].toLowerCase();
          }

          return false;
        })
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      });
    }

    return true;
  });
});

export const D0_PREPARE_8D_VALIDATION = object().shape({
  businessUnit: object().required(localeStrings("VALIDATORS.SELECT_BUSINESS_UNIT")),
  location: object().required(localeStrings("VALIDATORS.ENTER_LOCATION")),
  purpose: object().required(localeStrings("VALIDATORS.SELECT_PURPOSE_TYPE")),
  defectCode : object().required(localeStrings("VALIDATORS.SELECT_DEFECT_CODE")),
  customer: string().required(localeStrings("VALIDATORS.ENTER_CUSTOMER")),
  customerName: string()
  .matches(/^[0-9A-Za-z ]*$/, localeStrings('VALIDATORS.ENTER_VALID_CUSTOMER_NAME'))
  .required(localeStrings("VALIDATORS.ENTER_CUSTOMER_NAME")),
  newDefectCode : string()
       .when("defectCode", {
          is: (val:any) => {
            
            if(val?.value === 0) return true;
            else return false;
          },
          then: string()
              .required(localeStrings('VALIDATORS.ENTER_NEW_DEFECT_CODE')),
          otherwise:string()
      }),
  customerEmail: string()
    .email(localeStrings("VALIDATORS.ENTER_VALID_EMAIL"))
    .required(localeStrings("VALIDATORS.ENTER_EMAIL")),
});

export const purposeValidationSchema = object().shape({
  championTitle: string().required(localeStrings("VALIDATORS.ENTER_TITLE")),
  championName: string()
  .matches(/^[0-9A-Za-z ]*$/, localeStrings('VALIDATORS.ENTER_VALID_NAME'))
  .required(localeStrings("VALIDATORS.ENTER_NAME")),
  championPhone: string()
  .email(localeStrings("VALIDATORS.ENTER_VALID_EMAIL"))
  .required(localeStrings("VALIDATORS.ENTER_EMAIL")),
  teamMembers: array().of(
   object()
      .shape({
        teamMemberTitle: string().required(localeStrings("VALIDATORS.ENTER_TITLE")),
        teamMemberName: string()
        .matches(
          /^[0-9A-Za-z ]*$/,
          localeStrings('VALIDATORS.ENTER_VALID_NAME'),
      )
        .required(localeStrings("VALIDATORS.ENTER_NAME")),
        phoneNumber: string()
        .email(localeStrings("VALIDATORS.ENTER_VALID_EMAIL"))
        .required(localeStrings("VALIDATORS.ENTER_EMAIL")),
      })
  ).test(
    "unique",
    localeStrings("VALIDATORS.DUPLICATE_TEAM_MEMBER_FOUND"),
    function (array : any) {
      const idsAndNames = array.map((item : any) => item?.teamMemberTitle + item?.teamMemberName + item?.phoneNumber);
      const set = new Set(idsAndNames);
      return set.size === array.length;
    }
  ),
});

export const D2ValidationSchema = object().shape({
  jobNumber: string(),
  attachments: array().of(object()),
  description: string().required(localeStrings("VALIDATORS.PROBLEM_DESCRIPTION")).max(1000, localeStrings("VALIDATORS.ALLOWED_MAX_1000")),
});
export const D3ValidationSchema = object().shape({
  d3Description: string().required(localeStrings("VALIDATORS.DESCRIPTION_OF_CONTAINMENT")).max(1000, localeStrings("VALIDATORS.ALLOWED_MAX_1000")),
});

export const fiveWhyValidationSchema = object().shape({
  writeCellOrEquipment: string().required(localeStrings("VALIDATORS.PLEASE_ENTER_CELL")),
  underStandingPoint: string()
    .max(1000, localeStrings("VALIDATORS.ALLOWED_MAX_1000"))
    .required(localeStrings("VALIDATORS.PROBLEM_DESCRIPTION")),
    fiveWhys: array().of(
      object()
        .shape({
          whatCause: string().required(localeStrings("VALIDATORS.FILL_5_WHY")),
        })
        // @ts-ignore
        .unique("whatCause", 'Please enter unique description')
    ),
  rootCause: string().required(localeStrings("VALIDATORS.PLEASE_ENTER_ROOT_CAUSES")),
  correctiveAction: string()
    .required(localeStrings("VALIDATORS.PLEASE_FILL_CORRECTIVE_ACTION")),
  preventionAction: string()
    .required(localeStrings("VALIDATORS.PREVENTION_ACTION")),
});

export const D4ValidationSchema = object().shape({
  rootCause: string().required(localeStrings("VALIDATORS.PLEASE_ENTER_ROOT_CAUSES")),
});

export const D5ValidationSchema = object().shape({
  responsiblePerson: array().min(1).required(localeStrings("VALIDATORS.PLEASE_SELECT_RESPONSIBLE_PERSON")),
  targetDate: date().max(new Date(new Date().setFullYear(new Date().getFullYear() + 20)).toISOString().slice(0, 10),localeStrings("VALIDATORS.PLEASE_SELECT_VALID_DATE") ).min(new Date().toISOString().slice(0, 10), localeStrings("VALIDATORS.PAST_DATE_NOT_ALLOWED")).required(localeStrings("VALIDATORS.PLEASE_SELECT_TARGET_DATE")),
  followUpDate: date().max(new Date(new Date().setFullYear(new Date().getFullYear() + 20)).toISOString().slice(0, 10),localeStrings("VALIDATORS.PLEASE_SELECT_VALID_DATE") ).min(new Date().toISOString().slice(0, 10), localeStrings("VALIDATORS.PAST_DATE_NOT_ALLOWED")).required(localeStrings("VALIDATORS.PLEASE_SELECT_FOLLOWUP_DATE")),
  correctiveAction: string().required(localeStrings("VALIDATORS.PLEASE_FILL_CORRECTIVE_ACTION"))
});



export const preventionActionValidationSchema = object().shape({
  preventionAction: string()
      .required(localeStrings('VALIDATORS.PREVENTION_ACTION')),
});

export const cusFeedbackValidationScheme = object().shape({
  customerSatisfication: boolean()
      .required(localeStrings('VALIDATORS.PLEASE_SELECT_THUMBS')),
  customerNotes : string()
      .required(localeStrings('VALIDATORS.ENTER_NOTES')),
  attachement : array().of(object())
});

export const validateDraft = async (values: any) => {
  const validationSchemaDraft = object().shape({
    businessUnit:  object().required(localeStrings("VALIDATORS.SELECT_BUSINESS_UNIT")),
    location: object().required(localeStrings("VALIDATORS.ENTER_LOCATION"))
  });
  
  try {
    await validationSchemaDraft.validate(values, { abortEarly: false });
  } catch (errors:any) {
    // Handle validation errors for the specific fields
    return errors.inner.reduce((acc : any, error: any) => {
      if(error.path === "businessUnit") acc[error.path] = localeStrings("VALIDATORS.SELECT_BUSINESS_UNIT");
      if(error.path === "location") acc[error.path] = localeStrings("VALIDATORS.ENTER_LOCATION");
      return acc;
    }, {});
  }

  return {};
};

export const validateDraftDetails = async (formResponse: any, lastSavedSectionIndex: number): Promise<number> => {
  let currentIndex = lastSavedSectionIndex - 1;
  const eightDValidationSchemes = [
    D0_PREPARE_8D_VALIDATION,
      purposeValidationSchema,
      D2ValidationSchema,
      D3ValidationSchema,
      fiveWhyValidationSchema,
      D4ValidationSchema,
      D5ValidationSchema,
      preventionActionValidationSchema
  ];

  for (let currentFormPosition = 0; currentFormPosition < lastSavedSectionIndex; currentFormPosition++) {
      const sectionResponse = formResponse[currentFormPosition];
      const currentSectionValidator = eightDValidationSchemes[currentFormPosition];
      const isValidResponse = await currentSectionValidator.isValid(sectionResponse);
      
      if (isValidResponse === false) {
          currentIndex = currentFormPosition;
          break;
      }
  }

  return currentIndex;
};
