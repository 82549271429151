import { Box,Text, Flex} from "@chakra-ui/react";
import { TextAreaFormControl } from "../../Component/formComponent/Textarea";
import { localeStrings } from "../../utils/i18n";
import AccordionTitleDescription from './AccordionTitleDescription/AccordionTitleDescription.json';
import { AccordionComponent } from "./AccordionTitleDescription/Accordion";
import { EIGHT_8D_SECTION } from "../../utils/const";

function D3Containment({view, audit8DLogId}: {view : boolean; audit8DLogId?: number;}) {
  return (
    <>
      <Flex justifyContent={"space-between"}> 
       <Text pb={2} fontWeight="bold" fontSize={'md'}>{EIGHT_8D_SECTION.D3_CONTAINMENT}
       </Text>
       {audit8DLogId && <Text fontSize={'md'} fontWeight={"medium"}>{audit8DLogId}</Text>}
      </Flex>
      <AccordionComponent Title={AccordionTitleDescription.D3.Title} Description={AccordionTitleDescription.D3.Description} />
      <Box width={[ '100%','100%','75%','100%']}>
        <TextAreaFormControl
          required={true}
          placeholder={localeStrings("PLACEHOLDERS.ENTER_DESCRIPTION")}
          data-testid="description-test"
          label={localeStrings("CREATE_8D_LOG.DESCRIPTION_OF_CONTAINMENT")}
          name="d3Description"
          maxLength={1000}
          disabled={view}
        />
      </Box>
    </>
  );
}

export default D3Containment;
