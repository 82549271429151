import { Box,Flex,Text } from "@chakra-ui/react";
import { localeStrings } from "../../utils/i18n";
import { TextAreaFormControl } from "../../Component/formComponent";
import AccordionTitleDescription from './AccordionTitleDescription/AccordionTitleDescription.json';
import { AccordionComponent } from "./AccordionTitleDescription/Accordion";
import { EIGHT_8D_SECTION } from "../../utils/const";

function D4RootCause({view, audit8DLogId}: {view : boolean; audit8DLogId?:number;}) {
  return (
    <>
      <Flex justifyContent={"space-between"}>
       <Text pb={2} fontWeight="bold" fontSize={'md'}>{EIGHT_8D_SECTION.D4_ROOT_CAUSE}
       </Text>
       {audit8DLogId && <Text fontSize={'md'} fontWeight={"medium"}>{audit8DLogId}</Text>}
      </Flex>
      <AccordionComponent Title={AccordionTitleDescription.D4.Title} Description={AccordionTitleDescription.D4.Description} />
      <Box width={[ '100%','100%','75%','100%']}>
        <TextAreaFormControl
          required={true}
          isDisabled={true}
          placeholder={localeStrings("PLACEHOLDERS.ENTER_ROOT_CAUSES")}
          data-testid="root_causes"
          label={localeStrings("CREATE_8D_LOG.ROOT_CAUSES")} 
          name="rootCause"
          maxLength={1000}
        />
      </Box>
    </>
  );
}

export default D4RootCause;