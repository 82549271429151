import { Input, FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import { useField } from "formik";
import { commonFormComponentType } from "./FormComponent";
import DatePicke    from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DatePicker({ label,disabled, name,error ,touched,placeholder,setvalue,onChange = () => {},required,disableMinimumValidation = false, ...rest }: commonFormComponentType & {disableMinimumValidation?: boolean}) {
  return (
    <FormControl isInvalid={error && touched ? true : false}  isRequired={required}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <DatePicke disabled={disabled} autoComplete='off' showFullMonthYearPicker placeholderText={placeholder} id={name} minDate={disableMinimumValidation  ? undefined : new Date()} selected={rest.value ? new Date(`${rest.value}`): null} onChange={(date:any) =>  { onChange((date === null ? '' : date));}} customInput={<Input placeholder={placeholder} id={name} {...rest}   type="text"  />}  />
      <FormErrorMessage as="div">{error}</FormErrorMessage>
    </FormControl>
  );
}

export function DatePickerFormControl({ name, ...rest }: commonFormComponentType  & {disableMinimumValidation?: boolean}) {
  const [field, { error, touched },helper ] = useField(name);

  return <DatePicker {...rest} {...field}  error={error} touched={touched} onChange={(date) =>{ helper.setValue(date)}}  />;
}