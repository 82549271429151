import { azureFetcher } from "./fetcher";
import API_ENDPOINT from "./endpoints";
import {
  ALLOWED_NO_RECORD_PER_PAGE,
  EIGHT_D_CATEGORY,
  EIGHT_D_LISTING_SORT_COLUMN_KEYS,
  EIGHT_D_LISTING_SORT_TYPE,
} from "../utils/const";

export async function fetch8DData(id: string) {
    const { data } = await azureFetcher.get(API_ENDPOINT.GET_8D_DATA_BY_ID(id));
    return data;

}

export async function fetchD1FormData(onlyBusinessUnit = false) {
  try {
    if (onlyBusinessUnit) {
      const businessUnit = await azureFetcher.get(API_ENDPOINT.GET_BUSINESS_UNITS);
      if (businessUnit.data.length) {
        return businessUnit.data;
      } else return [];
    } else {
      const [businessUnit, Purpose] = await Promise.all([
        azureFetcher.get(API_ENDPOINT.GET_BUSINESS_UNITS),
        azureFetcher.get(API_ENDPOINT.GET_AUDIT_PURPOSE),
      ]);
      if (businessUnit.data.length && Purpose.data.length) {
        return { businessUnit: businessUnit.data, Purpose: Purpose.data };
      }
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
}

export async function fetchDLocationDataByBusinessUnit(businessUnitId: number) {
  try {
    const { data } = await azureFetcher.get(
      API_ENDPOINT.GET_LOCATION_BY_BUSINESS_UNIT(businessUnitId)
    );
    return Promise.resolve(data);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
}
export async function fetch8DTypes() {
  const { data } = await azureFetcher.get(API_ENDPOINT.GET_AUDIT_PURPOSE, {});
  return Promise.resolve(data);
}

export async function fetchDefectCode() {
  const { data } = await azureFetcher.get(API_ENDPOINT.GET_DEFECT_CODE, {});
  return Promise.resolve(data);
}
export async function fetchStatusDropdown() {
  try {
    const { data } = await azureFetcher.get(API_ENDPOINT.GET_STATUS_FILTER);
    return Promise.resolve(data);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
}

export async function create8DPurpose(body: any) {
  try {
    const { data } = await azureFetcher.post(API_ENDPOINT.POST8D_PURPOSE, JSON.stringify(body));
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function create8DProblemSolving(body: any) {
  try {
    const { data } = await azureFetcher.post(
      API_ENDPOINT.POST8D_PROBLEM_SOLVING,
      JSON.stringify(body)
    );
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
export async function create8DConcern(body: any) {
  try {
    const concernFormData = new FormData();
    concernFormData.append("id", `${body.id ?? 0}`);
    concernFormData.append("currentWorkFlowStepId", `${body.currentWorkFlowStepId ?? 2}`);
    concernFormData.append("isDraft", `${body.isDraft ?? false}`);
    concernFormData.append(
      "concern8d[0].jobNumber",
      body.concern8d?.length ? body.concern8d[0].jobNumber ?? "" : ""
    );
    concernFormData.append(
      "concern8d[0].concernDescription",
      body.concern8d?.length ? body.concern8d[0].concernDescription ?? "" : ""
    );
    concernFormData.append("MultiRecordUpdate", body?.multiRecordUpdate);
    if (body.files?.length) {
      body.files.forEach((file: any, index: any) => {
        const extras = file.extraInfo;
        if (!file.id && extras?.originalFileName) {
          concernFormData.append(
            `ConcernAttachment8d[${index}].fileName`,
            `${extras?.originalFileName}`
          );
          concernFormData.append(`ConcernAttachment8d[${index}].fileUrl`, `${extras?.filePathUrl}`);
          concernFormData.append(
            `ConcernAttachment8d[${index}].uniqueFileName`,
            `${extras?.uniqueFileName}`
          );
        }
        if (file.id) {
          concernFormData.append(`ConcernAttachment8d[${index}].fileName`, `${file?.fileName}`);
          concernFormData.append(`ConcernAttachment8d[${index}].fileUrl`, `${file?.fileUrl}`);
          concernFormData.append(
            `ConcernAttachment8d[${index}].uniqueFileName`,
            `${file?.uniqueFileName}`
          );
        }
      });
    }
    const { data } = await azureFetcher.post(API_ENDPOINT.POST8D_CONCERN, concernFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    });

    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
export async function create8DContainment(body: any) {
  try {
    const { data } = await azureFetcher.post(API_ENDPOINT.POST8D_CONTAINMENT, JSON.stringify(body));
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function create8D5Whys(body: any) {
  try {
    const { data } = await azureFetcher.post(API_ENDPOINT.POST8D_DRAFT_5_WHY, JSON.stringify(body));

    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function create8DRootCause(body: any) {
  try {
    const { data } = await azureFetcher.post(API_ENDPOINT.POST8D_DRAFT_D4, JSON.stringify(body));
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function create8D_D5_D6(body: any) {
  try {
    const { data } = await azureFetcher.post(API_ENDPOINT.POST8D_DRAFT_D5_6, JSON.stringify(body));
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function create8D_D7(body: any) {
  try {
    const { data } = await azureFetcher.post(API_ENDPOINT.POST8D_DRAFT_D7, JSON.stringify(body));
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function create8D_D8(body: any) {
  console.log(body);

  try {
    const concernFormData = new FormData();
    concernFormData.append("id", `${body.id ?? 0}`);
    concernFormData.append("currentWorkFlowStepId", `${body.currentWorkFlowStepId ?? 8}`);
    concernFormData.append(
      "feedback8d[0].CustomerSatisfication",
      body.feedback8d[0].CustomerSatisfaction ?? ""
    );
    concernFormData.append("feedback8d[0].CustomerNotes", body.feedback8d[0].CustomerNotes ?? "");

    if (body.files?.length) {
      body.files.forEach((file: any, index: any) => {
        const extras = file.extraInfo;
        if (!file?.id) {
          concernFormData.append(
            `ConcernAttachment8d[${index}].fileName`,
            `${extras?.originalFileName}`
          );
          concernFormData.append(`ConcernAttachment8d[${index}].fileUrl`, `${extras?.filePathUrl}`);
          concernFormData.append(
            `ConcernAttachment8d[${index}].uniqueFileName`,
            `${extras?.uniqueFileName}`
          );
        }
      });
    }

    const { data } = await azureFetcher.post(API_ENDPOINT.POST8D_DRAFT_D8, concernFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    });

    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export type fetch8DLogListPayloadType = {
  BusinessUnitId?: number;
  LocationId?: number;
  PurposeId?: number;
  StatusID?: number;
  EnableCreatedBy?: boolean;
  PageNumber?: number;
  PageSize?: number;
  isLoadMore?: boolean;
  StatusCategory?: EIGHT_D_CATEGORY;
  SortColumn?: EIGHT_D_LISTING_SORT_COLUMN_KEYS;
  SortType?: EIGHT_D_LISTING_SORT_TYPE;
  ForDashboard?: "yes" | "no" | undefined;
  FromDate?: string;
  ToDate?: string;
  DefectCodeId? : number;
  sortField ?: any
};

export async function getTableData(payload: fetch8DLogListPayloadType) {
  let url = API_ENDPOINT.FETCH_8D_LOG_LIST;

  const pageNo = payload.PageNumber ? payload.PageNumber : 1;
  const pageSize = payload.PageSize ? payload.PageSize : ALLOWED_NO_RECORD_PER_PAGE;
  const {
    BusinessUnitId,
    LocationId,
    PurposeId,
    StatusID,
    EnableCreatedBy,
    StatusCategory,
    SortType,
    SortColumn,
    ForDashboard,
    FromDate,
    ToDate,
    sortField,
    DefectCodeId
  } = payload;

  const pagenationQuery = `?PageNumber=${pageNo}&PageSize=${pageSize}`;
  const businessUnitQuery = `${BusinessUnitId ? `&BusinessUnitId=${BusinessUnitId}` : ``}`;
  const locationQuery = `${LocationId ? `&locationId=${LocationId}` : ``}`;
  const purposeQuery = `${PurposeId ? `&purposeId=${PurposeId}` : ``}`;
  const statusQuery = `${StatusID ? `&StatusID=${StatusID}` : ``}`;
  const createdByMeQuery = `${EnableCreatedBy ? `&EnableCreatedBy=${EnableCreatedBy}` : ``}`;
  const statusCategoryQuery = `${StatusCategory ? `&StatusCategory=${StatusCategory}` : ``}`;
  const sortQuery = SortColumn && SortType ? `&SortColumn=${SortColumn}&SortType=${SortType}` : "";
  const ForDashboardQuery = ForDashboard ? `&ForDashboard=${ForDashboard}` : "";
  const FromDateQuery = `${FromDate ? `&fromDate=${FromDate}` : ``}`;
  const ToDateQuery = `${ToDate ? `&toDate=${ToDate}` : ``}`;
  const DefectCode = `${DefectCodeId ? `&DefectCodeId=${DefectCodeId}` : ``}`;
  const SortField = `${sortField?.sortColumn?.length ? `&sortColumn=${sortField?.sortColumn}` : ''}${sortField?.sortColumn?.length ? `&sortType=${sortField?.sortType}` : ""}`
  url =
    url +
    pagenationQuery +
    businessUnitQuery +
    locationQuery +
    purposeQuery +
    statusQuery +
    createdByMeQuery +
    statusCategoryQuery +
    sortQuery +
    ForDashboardQuery +
    FromDateQuery +
    DefectCode +
    SortField +
    ToDateQuery;

  const data = await azureFetcher.get(url);
  if (data.status === 200) return data.data;
  return [];
}

export type fetchSupervisorLogListPayloadType = {
  statusCategory: 0 | 1 | 2;
  BusinessUnitId?: number;
  LocationId?: number;
  PurposeId?: number;
  PageNumber?: number;
  PageSize?: number;
  isLoadMore?: boolean;
  SortColumn?: EIGHT_D_LISTING_SORT_COLUMN_KEYS;
  SortType?: EIGHT_D_LISTING_SORT_TYPE;
};

export async function getSupervisor8DLogs({
  statusCategory,
  BusinessUnitId,
  LocationId,
  PurposeId,
  SortColumn,
  SortType,
  PageNumber,
  PageSize
}: fetchSupervisorLogListPayloadType) {
    let url = '';

    const pageNo = PageNumber ? PageNumber : 1;
    const pageSize = PageSize ? PageSize : ALLOWED_NO_RECORD_PER_PAGE;
    const pagenationQuery = `?PageNumber=${pageNo}&PageSize=${pageSize}`;
    const statusCategoryQuery = `${statusCategory ? `&StatusCategory=${statusCategory}` : ``}`;

    const businessUnitQuery = `${BusinessUnitId ? `&BusinessUnitId=${BusinessUnitId}` : ``}`;
    const locationQuery = `${LocationId ? `&locationId=${LocationId}` : ``}`;
    const purposeQuery = `${PurposeId ? `&purposeId=${PurposeId}` : ``}`;
    const sortQuery = (SortColumn && SortType) ? `&SortColumn=${SortColumn}&SortType=${SortType}` :  '';

    url = url
    +pagenationQuery
    +statusCategoryQuery
    +sortQuery
    +businessUnitQuery
    +locationQuery
    +purposeQuery;

    const data = await azureFetcher.get(API_ENDPOINT.GET_SUPERVISOR_8D_TABLE_DATA(url));
    if (data.status === 200) return data.data;
    return [];

}

export async function fetchSupervisorBusinessUnit(onlyBusinessUnit = false) {
  try {
    if (onlyBusinessUnit) {
      const businessUnit = await azureFetcher.get(API_ENDPOINT.GET_BUSINESS_UNITS_SUPERVISOR);
      if (businessUnit.data.length) {
        return businessUnit.data;
      } else return [];
    } else {
      const [businessUnit, Purpose] = await Promise.all([
        azureFetcher.get(API_ENDPOINT.GET_BUSINESS_UNITS_SUPERVISOR),
        azureFetcher.get(API_ENDPOINT.GET_AUDIT_PURPOSE),
      ]);
      if (businessUnit.data.length && Purpose.data.length) {
        return { businessUnit: businessUnit.data, Purpose: Purpose.data };
      }
    }
  } catch (err) {
    console.log(err);
    return {
      businessUnit: [],
      Purpose: [],
    };
  }
}

export async function fetch8DFormData(id: any) {
  try {
    const { data } = await azureFetcher.get(API_ENDPOINT.GET_8D_FORM_DATA_BY_ID(id));
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function approvePreventive({ audit8DHeaderId, comments }: any) {
  try {
    let url = `?audit8dHeaderId=${audit8DHeaderId}&comments=${comments}`;
    const data = await azureFetcher.patch(API_ENDPOINT.PATCH_SUPERVISOR_APPROVAL_PREVENTIVE(url));
    if (data.status === 200) return data.data;
    return [];
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function rejectPreventive({ audit8DHeaderId, comments }: any) {
  try {
    let url = `?audit8dHeaderId=${audit8DHeaderId}&comments=${comments}`;
    const data = await azureFetcher.patch(API_ENDPOINT.PATCH_SUPERVISOR_REJECT_PREVENTIVE(url));
    if (data.status === 200) return data.data;
    return [];
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function approveContainment({ audit8DHeaderId, comments }: any) {
  try {
    let url = `?audit8dHeaderId=${audit8DHeaderId}&comments=${comments}`;
    const data = await azureFetcher.patch(API_ENDPOINT.PATCH_SUPERVISOR_APPROVAL_CONTAINMENT(url));
    if (data.status === 200) return data.data;
    return [];
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function rejectContainment({ audit8DHeaderId, comments }: any) {
  try {
    let url = `?audit8dHeaderId=${audit8DHeaderId}&comments=${comments}`;
    const data = await azureFetcher.patch(API_ENDPOINT.PATCH_SUPERVISOR_REJECT_CONTAINMENT(url));
    if (data.status === 200) return data.data;
    return [];
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function createChildForm({ audit8DHeaderId }: any) {
  try {
    const data = await azureFetcher.get(API_ENDPOINT.CREATE_CHILD_FORM(audit8DHeaderId));
    if (data.status === 200) return data.data;
    return [];
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function customerDeleteContainment({ audit8DHeaderId, comments }: any) {
  try {
    const data = await azureFetcher.patch(`${API_ENDPOINT.CONTAINMENT_CUSTOMER_REJECT}?audit8dHeaderId=${audit8DHeaderId}&comments=${comments}`);
    if (data.status === 200) return data.data;
    return [];
  } catch (err) {
    console.log(err);
    return err;
  }
}


export async function send8DtoCustomer({ audit8DHeaderId }: any) {
  const data = await azureFetcher.get(API_ENDPOINT.GET_SEND_8D_TO_CUST(audit8DHeaderId));
  return data.status;
}

export async function deleteAttachment({ audit8DHeaderId, Id }: any) {
  try {
    const url = `?audit8dHeaderId=${audit8DHeaderId}&Id=${Id}`;
    const data = await azureFetcher.delete(API_ENDPOINT.DELETE_ATTACHMENTS(url));
    if (data.status === 200) return data.status;
    return [];
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function deleteTeamMembers({ audit8DHeaderId, Id }: any) {
  try {
    const url = `?auditHeaderId=${audit8DHeaderId}&TeammemberId=${Id}`;
    const data = await azureFetcher.delete(API_ENDPOINT.DELETE_TEAM_MEMBER(url));
    if (data.status === 200) return data.status;
    return [];
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function approve({
  itemId,
  statusId,
  comments,
  status,
}: {
  itemId: number;
  statusId: number;
  comments: string;
  status: string;
}) {
  if (statusId === 5) {
    if (status === "approve")
      return await approvePreventive({ audit8DHeaderId: itemId, comments: comments });
    else return await rejectPreventive({ audit8DHeaderId: itemId, comments: comments });
  } else {
    if (status === "approve")
      return await approveContainment({ audit8DHeaderId: itemId, comments: comments });
    else return await rejectContainment({ audit8DHeaderId: itemId, comments: comments });
  }
}
